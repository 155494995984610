import React, { useState, useEffect } from 'react';
import TButtonItem from './TButtonItem';
import { leadsService } from '../services';
import {
  IonIcon,
  IonSpinner,
  IonContent,
  IonPopover
} from '@ionic/react';
import { reader } from 'ionicons/icons';
import { Lead } from '../types/Lead';

export interface AiSummaryProps {
  lead: Lead;
}

const AiSummary: React.FC<AiSummaryProps> = ({ lead }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [summary, setSummary] = useState<string | null>(null);
  const [popoverEvent, setPopoverEvent] = useState<any>(null);
  const [audioSrc, setAudioSrc] = useState<string | null>(null);

  useEffect(() => {
    if (summary) {
      // Fetch the audio file from the TTS service and set the audioSrc state
      const encodedText = encodeURIComponent(summary);
      const audioUrl = `https://tecobi-tts-4dzvfxebxa-uc.a.run.app/tts-openai?voice=shimmer&text=${encodedText}`;
      setAudioSrc(audioUrl);
    }
  }, [summary]);

  return (
    <>
      <TButtonItem
        color="secondary"
        disabled={loading} // Disable the button when loading is true
        onClick={async (e: any) => {
          e.persist(); // To retain the event for asynchronous operation
          setLoading(true);
          const res = await leadsService.fetchAiSummary(lead.client, lead.id);
          setLoading(false);
          if (res?.replies?.[0]) {
            setSummary(res.replies[0]);
            setPopoverEvent(e);
          }
        }}
      >
        {loading ? (
          <>
            <IonSpinner name="crescent" slot="start" />
            Loading...
          </>
        ) : (
          <>
            <IonIcon icon={reader} slot="start" />
            Get AI Summary
          </>
        )}
      </TButtonItem>

      <IonPopover
        isOpen={!!popoverEvent}
        event={popoverEvent}
        onDidDismiss={() => setPopoverEvent(null)}
      >
        <IonContent className="ion-padding">
          {audioSrc && (
            <audio controls style={{ width: '100%' }}>
              <source src={audioSrc} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          )}
          {summary}
        </IonContent>
      </IonPopover>
    </>
  );
};

export default AiSummary;
