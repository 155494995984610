import React, { useCallback } from 'react';
import { TReportsPage } from '../components';
import { ninjaTeamCommissionsService } from '../services';
import { useDarkTheme } from '../hooks';
import DataTable from 'react-data-table-component';
import { util } from '../core';
import moment from 'moment';
import {
  IonList,
  IonItemDivider,
  IonCard,
  IonCardContent
} from '@ionic/react';
import '../styles/Reports.scss';

const maxWidth = '95px';

const customStyles: any = {
  cells: {
    style: {
      '&:nth-of-type(5)': {
        backgroundColor: '#8BC642',
      },
    },
  },
};

const otherCustomStyles: any = {
  cells: {
    style: {
      '&:nth-of-type(4)': {
        backgroundColor: '#8BC642',
      },
    },
  },
};

const otherCommissionsColumns = [
  {
    name: 'Category',
    selector: (row: any) => row.category,
    sortable: true,
  },
  {
    name: 'Gross Profit',
    selector: (row: any) => row.gross_profit,
    sortable: true,
    right: true,
    maxWidth
  },
  {
    name: 'Multiplier',
    selector: (row: any) => row.multiplier,
    sortable: true,
    right: true,
    maxWidth
  },
  {
    name: 'Commission',
    selector: (row: any) => row.commission,
    sortable: true,
    right: true,
    maxWidth
  }
];

const commissionsColumns = [
  {
    name: 'Place',
    selector: (row: any) => row.place,
    sortable: true,
    right: true,
    maxWidth
  },
  {
    name: 'Team Name',
    selector: (row: any) => row.team_name,
    sortable: true,
  },
  {
    name: 'Main Gross Profit',
    selector: (row: any) => row.main_gross_profit,
    sortable: true,
    right: true,
    maxWidth
  },
  {
    name: 'Multiplier',
    selector: (row: any) => row.multiplier,
    sortable: true,
    right: true,
    maxWidth
  },
  {
    name: 'Commission',
    selector: (row: any) => row.commission,
    sortable: true,
    right: true,
    maxWidth
  }
];

const updateDateRange = (e: any) => {
  let val = e.detail.value;
  let monthAdder = 0;
  if (val === 'Last Month') {
    monthAdder = -1;
  } else if (val === 'Previous Month') {
    monthAdder = -2;
  } else if (val === '3 Months Back') {
    monthAdder = -3;
  }
  if (e.detail.value) {
    const ret = {
      dateRange: e.detail.value,
      since: moment().add(monthAdder, 'month').startOf('month').toISOString(),
      until: moment().add(monthAdder, 'month').endOf('month').toISOString()
    };
    return ret;
  }
};

const NinjaTeamCommissions: React.FC = () => {
  const isDark = useDarkTheme();
  util.createTecobiDarkDataTableTheme();
  const loadData = useCallback(
    async ({ since, until, filters }: any) => {
      //const { classification, leadProvider, clientIds, pipelineStatusIds} = filters;

      const requests:Array<any> = [
        ninjaTeamCommissionsService.retrieveNinjaTeamCommissionsReport(since, until),
        ninjaTeamCommissionsService.retrieveNinjaTeamOtherCommissionsReport(since, until),
      ];
      const [commissions, otherCommissions] = await Promise.all(requests);
      return {
        commissions,
        otherCommissions,
        since
      };
    },
    []
  );

  return (
    <TReportsPage
      updateDateRange={updateDateRange}
      defaultSince={moment().add(-1, 'month').startOf('month').toISOString()}
      defaultUntil={moment().add(-1, 'month').endOf('month').toISOString()}
      defaultDateRange="Last Month"
      segments={['3 Months Back', 'Previous Month', 'Last Month', 'This Month']}
      onLoadData={loadData}
      filterProps={{ showLeadProvider: false, showNewLeadsOnly: false, showMultiClientSelector: false, showPipelineStatuses: false }}
    >
      {({ state }) => {
        const { data } = state;
        const since = moment(data.since);
        return (
          <>
            <IonList><IonItemDivider>{since.format('MMMM YYYY')} Commissions</IonItemDivider></IonList>
            {!!data?.commissions && Object.keys(data?.commissions).map((keyName: any, keyIndex: any) => (
              <IonCard key={`${keyName}-${keyIndex}`}>
                <IonCardContent>
                  <DataTable
                    key={keyName}
                    theme={isDark ? 'tecobi-dark' : 'default'}
                    title={keyName.replace('_', ' ').toUpperCase()}
                    columns={commissionsColumns}
                    customStyles={customStyles}
                    data={data?.commissions?.[keyName]}
                    highlightOnHover
                  />
                </IonCardContent>
              </IonCard>
            ))}
            <IonList><IonItemDivider>{since.format('MMMM YYYY')} Other Commissions</IonItemDivider></IonList>
            {!!data?.otherCommissions && Object.keys(data?.otherCommissions).map((keyName: any, keyIndex: any) => (
              <IonCard key={`${keyName}-${keyIndex}`}>
                <IonCardContent>
                  <DataTable
                    key={`${keyName}-${keyIndex}`}
                    title={keyName.toUpperCase()}
                    columns={otherCommissionsColumns}
                    customStyles={otherCustomStyles}
                    data={data?.otherCommissions?.[keyName]}
                    theme={isDark ? 'tecobi-dark' : 'default'}
                    highlightOnHover
                  />
                </IonCardContent>
              </IonCard>
            ))}

          </>
        );
      }}
    </TReportsPage>
  );
};

export default NinjaTeamCommissions;
