import React from 'react';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import TAvatar from './TAvatar';
import { QuickQualificationRowProps } from './QuickQualificationRow';
import { Trade } from '../types/Trade';
import moment from 'moment';

export interface TradesRowProps {
  item: Trade;
}

const TradesRow: React.FC<QuickQualificationRowProps> = ({ item }) => {
  const { ymmt, client, lead, updated_at, mileage } = item;

  return (
    <IonItem
      className="ion-activatable pointer"
      routerLink={`/text-messages/conversation/${client}/${lead.id}/`}
    >
      <TAvatar slot="start" alt={`${lead.first_name} ${lead.last_name}`} />
      <IonLabel>
        <IonText className="ion-multiline">
          <h2>{`${lead.first_name} ${lead.last_name}`}</h2>
          <p>{ymmt}</p>
          <span className="detail">{`${mileage.toLocaleString()} miiles`}</span>
          <span className="detail">{moment(updated_at).fromNow()}</span>
        </IonText>
      </IonLabel>
    </IonItem>
  )
}

export default TradesRow;