import React, { useContext } from 'react';
import '../../styles/components/BlastModals.scss';
import TModal from './TModal';
import { blastsService } from '../../services';
import { IonList, IonItem, IonLabel } from '@ionic/react';
import TDateTimeItem from '../../components/TDateTimeItem';
import { TTextItem } from '..';
import {
  person,
  time,
  personCircle,
  speedometer,
  locate,
  funnel,
  calendar,
  briefcase,
  send,
  thumbsUp,
  thumbsDown,
  key,
  card,
  carSport,
  archive,
} from 'ionicons/icons';
import moment from 'moment';
import ChatBubble from '../ChatBubble';
import { AppContext } from '../../context/AppContext';

export interface BlastModalProps {
  item: any;
  blastType: string;
  onDidDismiss?: () => any;
}

const BlastModal: React.FC<BlastModalProps> = ({
  item,
  blastType,
  onDidDismiss
}) => {
  const appContext = useContext(AppContext);
  const { user, clientProviders } = appContext.state;
  const statusIcon = blastsService.getStatusIcon(item);
  const isLeadBlast = blastType === 'leads';

  const getImportedLabel = (value: string) => {
    switch (value) {
      case '0':
        return 'Imported Never Replied';
      case '1':
        return 'Imported Replied';
      case '2':
        return 'Not Imported + Imported Replied';
      case '3':
        return 'Not Imported';
      default:
        return 'All';
    }
  }

  return (
    <TModal
      isOpen={item}
      onDidDismiss={onDidDismiss}
      title="Blast Details"
      className="blast-detail-modal"
    >
      {item && (
        <IonList>
          <IonItem lines="none">
            <IonLabel className="ion-text-end">Message Preview</IonLabel>
          </IonItem>
          <ChatBubble
            id={item.id}
            sender={item.sender}
            message={item.message}
            lines="full"
            dateSent={moment(item.send_at).format('MMM D, YYYY h:mma')}
            mediaSet={
              item.media
                ? [
                    {
                      id: 'photo',
                      media: item.media,
                      content_type: 'image/jpeg'
                    }
                  ]
                : []
            }
            outgoing
          />
          {!isLeadBlast && (
            <>
              <TDateTimeItem
                icon={calendar}
                disabled={true}
                fromDisabled={true}
                toDisabled={true}
                fromValue={item.booked_date_since}
                toValue={item.booked_date_until}
                lines="inset"
                label="Sold Date"
                range
              />
            </>
          )}
          <TTextItem icon={person} label="Sender" text={item.sender} />
          <TTextItem
            icon={time}
            label="Send At"
            text={moment(item.send_at).format('MMM DD, YYYY hh:mma')}
          />
          {user?.is_admin && (
            <TTextItem icon={time} label="Send Over" text={item.send_over} />
          )}
          <TTextItem
            icon={statusIcon.icon}
            label="Status"
            text={
              item.completed_at
                ? 'Completed'
                : item.started_at
                ? 'Started'
                : 'Scheduled'
            }
          />
          <TTextItem
            icon={personCircle}
            label="Audience"
            text={item.sent_count}
          />
          <TTextItem
            icon={speedometer}
            label="Response Rate"
            text={`${item.response_rate}%`}
          />
          {isLeadBlast && (
            <TTextItem
              icon={archive}
              label="Imported Leads"
              text={getImportedLabel(item.imported_replied)}
            />
          )}
          {isLeadBlast && !!item.master_provider && (
            <TTextItem
              icon={send}
              label="Lead Provider"
              text={
                item.master_provider
                  ? clientProviders?.find(
                      (it: any) => it.id === item.master_provider
                    )?.name
                  : 'All'
              }
            />
          )}
          {isLeadBlast && !item.master_provider && (
            <TTextItem
              icon={send}
              label="Lead Providers"
              text={
                item.master_provider_names?.length
                  ? item.master_provider_names
                  : 'All'
              }
            />
          )}
          {isLeadBlast && !!item.lead_created_at_max && !!item.lead_created_at_min && (
          <TTextItem
            icon={calendar}
            label="Leads Created Range"
            text={moment(item.lead_created_at_min).format('MMM DD, YYYY hh:mma') + ' - ' + moment(item.lead_created_at_max).format('MMM DD, YYYY hh:mma')}
          />
          )}

          {!!item.blast_autoreplies?.length && (
            <>
              <TTextItem
                icon={thumbsUp}
                label="Positive Auto Reply Count"
                text={item.positive_auto_reply_count}
              />
              <TTextItem
                icon={thumbsDown}
                label="Negative Auto Reply Count"
                text={item.negative_auto_reply_count}
              />
            </>
          )}
          {isLeadBlast && (
            <TTextItem
              icon={personCircle}
              label="Leads Assigned To"
              text={item.assigned_to_users_names}
            />
          )}
          {isLeadBlast && (
            <>
              <TTextItem
                icon={locate}
                label="Targeting"
                text={item.status_names?.join(', ')}
              />
              <TTextItem
                icon={key}
                label="Trade Stage"
                text={item.trade_stage}
              />
              <TTextItem
                icon={card}
                label="Credit Stage"
                text={item.credit_stage}
              />
              <TTextItem
                icon={carSport}
                label="Vehicle Stage"
                text={item.vehicle_stage}
              />
              <TTextItem
                icon={calendar}
                label="Appointment Stage"
                text={item.appt_stage}
              />
            </>
          )}
          {item.lead_classification && (
            <TTextItem
              icon={briefcase}
              label="Classification"
              text={item.lead_classification}
            />
          )}

          <TTextItem icon={speedometer} label="Limit" text={item.limit} />
          <TTextItem
            icon={time}
            wrapLabel
            label={`Last Message Age (${isLeadBlast ? 'Hours' : 'Days'})`}
            text={
              isLeadBlast
                ? item.last_message_age_hours
                : item.last_message_age_hours/24
            }
          />
          {isLeadBlast && (
            <TTextItem
              icon={funnel}
              label="Ordering"
              text={item.ordering === '-id' ? 'Newest First' : 'Oldest First'}
            />
          )}
          <TTextItem
            icon={calendar}
            label="Started"
            text={
              item.started_at
                ? moment(item.started_at).format('MMM DD, YYYY hh:mma')
                : 'Not Yet Started'
            }
          />
          <TTextItem
            icon={calendar}
            label="Completed"
            text={
              item.completed_at
                ? moment(item.completed_at).format('MMM DD, YYYY hh:mma')
                : 'Not Yet Completed'
            }
          />
        </IonList>
      )}
    </TModal>
  );
};

export default BlastModal;
