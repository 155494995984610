import React, { useCallback, useEffect, useState } from "react";
import TModal from "./TModal";
import moment from "moment";
import DataTable from "react-data-table-component";
import { http, util } from "../../core";
import { useHistory } from "react-router-dom";
import TLoading from "../TLoading";
import { IonSearchbar } from "@ionic/react";
import { useDarkTheme } from "../../hooks";

export interface PaginatedLeadListModalProps {
  isOpen: boolean;
  userId: number;
  userIdInParams?: boolean;
  userType?: string;
  since: Date;
  until: Date;
  title: string;
  endpoint: string;
  dateLabel?: string;
  dateTimeField: string;
  tableTitle?: string;
  extraQueryPrams?: any;
  onDidDismiss: () => any;
}

const customStyles: any = {
  rows: {
    style: {
      cursor: "pointer",
    },
  },
};

const PaginatedLeadListModal: React.FC<PaginatedLeadListModalProps> = ({
  isOpen,
  userId,
  since,
  until,
  title,
  endpoint,
  dateTimeField,
  tableTitle,
  extraQueryPrams,
  dateLabel,
  userIdInParams,
  userType,
  onDidDismiss,
}) => {
  const history = useHistory();
  const [leads, setLeads] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState(dateTimeField);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const isDark =  useDarkTheme();
  const countPerPage = 7;

  util.createTecobiDarkDataTableTheme();

  const getLeadList = useCallback(async () => {
    setLoading(true);
    let url = `/${endpoint}/`;
    if (userId && !userIdInParams) {
      url += `${userId}/`;
    }
    const queryParams = new URLSearchParams({
      page: String(page),
      page_size: String(countPerPage),
      [dateTimeField + "__gte"]: since?.toISOString(),
      [dateTimeField + "__lte"]: until.toISOString(),
      ordering: sortDirection === "asc" ? sortField : `-${sortField}`,
      search,
    });
    if (userId && userIdInParams) {
      queryParams.append("user_id", String(userId));
    }
    if (userType) {
      queryParams.append("user_type", userType);
    }
    if (extraQueryPrams) {
      Object.keys(extraQueryPrams).forEach((key) => {
        queryParams.append(key, extraQueryPrams[key]);
      });
    }
    const res = await http.authorizedRequest({
      method: "GET",
      url: `${url}?${queryParams.toString()}`,
      type: endpoint,
      exclusive: true,
    });
    setLeads(res.data);
    setLoading(false);
  }, [
    page,
    userId,
    dateTimeField,
    endpoint,
    since,
    until,
    userIdInParams,
    userType,
    sortField,
    sortDirection,
    extraQueryPrams,
    search,
  ]);

  useEffect(() => {
    getLeadList();
  }, [getLeadList]);

  const columns = [
    {
      name: "Name",
      selector: (row: any) => row.full_name,
      sortable: false,
    },
    {
      name: dateLabel ?? "Date",
      selector: (row: any) => row.sold ?? row.created_at,
      sortField: dateTimeField, // Ensure proper sortField mapping
      format: (row: any) => moment(row.sold ?? row.created_at).format("lll"),
      sortable: true,
    },
  ];

  return (
    <TModal isOpen={isOpen} title={title} onDidDismiss={onDidDismiss}>
      <IonSearchbar
        value={search}
        onIonChange={(e) => setSearch(e.detail.value!)}
        onIonClear={() => setSearch("")} // Reset search when the "x" is clicked
        placeholder='Search by name'
        debounce={500}
      />
      <TLoading visible={loading} text='Loading' />
      <DataTable
        title={tableTitle}
        columns={columns}
        customStyles={customStyles}
        data={leads?.results}
        theme={isDark ? "tecobi-dark" : "default"}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={leads?.count}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onRowClicked={(row: any) => {
          history.push(
            `/text-messages/conversation/${row.client_id}/${row.id}/`
          );
          onDidDismiss();
        }}
        onChangePage={(page) => setPage(page)}
        onSort={(column, direction) => {
          setSortField(column.sortField || dateTimeField);
          setSortDirection(direction);
        }}
        defaultSortFieldId={2} // Default sort by date column
        defaultSortAsc={false} // Default descending order
      />
    </TModal>
  );
};

export default PaginatedLeadListModal;
