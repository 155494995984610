import React, { useState } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonButton,
} from '@ionic/react';
import { util } from '../core';
import { LeadListModal } from '../components/modals';
import '../styles/components/MetricCard.scss';

export interface MetricCardProps {
  title: string;
  value: any;
  uom?: string;
  leads?: any;
  preContent?: any;
  onClick?: any;
  buttonLabel?: string;
  color?: string;
  headerColor?: string;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  uom,
  leads,
  preContent,
  onClick,
  buttonLabel,
  color,
  headerColor
}) => {
  const [showModal, setShowModal] = useState(false);
  const displayModal = () => setShowModal(true);

  return (
    <IonCard className="metric-card" color={color}>
      <IonCardHeader color={headerColor}>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      {preContent}
      <IonCardContent>
        <strong onClick={displayModal} style={{ cursor: `${leads ? 'pointer' : 'default'}` }}>
          {value?.toLocaleString()}
        </strong>
        {!!uom && (
          <label>{isNaN(value) ? uom : util.getPluralizedString(uom, value)}</label>
        )}
        {onClick && (
          <IonButton
            fill="outline"
            onClick={onClick}
          >
            {buttonLabel}
          </IonButton>
        )}
      </IonCardContent>
      {leads && (
        <LeadListModal
          isOpen={showModal}
          leads={leads}
          title={title}
          onDidDismiss={() => { setShowModal(false) }}
        />
      )}
    </IonCard>
  );
};

export default MetricCard;
