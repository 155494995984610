import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import {
  ChatBubble,
  TInfiniteScrollPage,
  EmojiPicker,
  ChatHeader,
  ChatFooter,
  ChatMentionsHelper,
  ChatProfileTooltip,
  LeadDuplicates,
  LeadTypingUsers,
} from "../components";
import {
  IonItemDivider,
  IonIcon,
  IonButton,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  leadsService,
  pushNotifications,
  inboundLeadsService,
  forcedTrainingSessionService,
} from "../services";
import moment from "moment";
import {
  util,
  appNotification,
  userDefaults,
  native,
  http,
  loadingIndicator,
} from "../core";
import { AppContext } from "../context/AppContext";
import { LeadContext } from "../context/LeadContext";
import { useLiveDBRef, useRequiresClockIn, useOnClickOutside } from "../hooks";
import {
  FacebookAdPreviewModal,
  PauseModal,
  MediaPreviewModal,
  QuickRepliesModal,
} from "../components/modals";
import { call } from "ionicons/icons";
import { NotificationsContext } from "../context/NotificationsContext";
import {
  ChatMessageBoxContext,
  appendMessageText,
  setMessageFocus,
} from "../context/ChatMessageBoxContext";
import { useRouter } from "../hooks/";
import TextMessageConversationFab from "../components/TextMessageConversationFab";

export function isRecentMessage(msg: any) {
  return Date.now() - new Date(msg.created).getTime() < 60 * 60 * 1000;
}

export function isMessageCorrectLead(msg: any, lead: any) {
  return msg.lead === lead?.id;
}

export function messagePassesAdminCheck(msg: any, user: any) {
  return !msg.admins_only || user.is_admin;
}

export function messagePassesInternalCheck(msg: any, user: any) {
  return !msg.internal_only || user.is_staff;
}

export function shouldAppendMessage(msg: any, lead: any, user: any) {
  //message is less than 1hr old. This is to not put in any messages that get
  //left in firebase
  const isRecent = isRecentMessage(msg);
  const isCorrectLead = isMessageCorrectLead(msg, lead);
  const passesAdminCheck = messagePassesAdminCheck(msg, user);
  const passesInternalCheck = messagePassesInternalCheck(msg, user);

  return isCorrectLead && passesAdminCheck && passesInternalCheck && isRecent;
}

export function getMessageLiveURL(lead: any) {
  return !!lead
    ? `/text-messages/conversation/${lead?.client}/${lead?.id}/`
    : null;
}

const TextMessageConversationContent: React.FC = () => {
  const router = useRouter();
  const emojiRef = useRef<any>();
  const textConversationPageRef = useRef<null | HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement | null>(null);
  const { dispatch: notifDispatch } = useContext(NotificationsContext);
  const { state: appState, dispatch: appDispatch } = useContext(AppContext);
  const { user, keyboardOpen } = appState;
  const loadingLead = useRef<any>();
  const leadContext = useContext(LeadContext);
  const [isPageActive, setIsPageActive] = useState(true);
  const [loadingAll, setLoadingAll] = useState(false);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  useIonViewWillEnter(() => setIsPageActive(true));

  useEffect(() => {
    const fetchForcedTrainingSession = async () => {
      const { forcedTrainingSessions } =
        await forcedTrainingSessionService.load();
      appDispatch({ type: "set", value: { forcedTrainingSessions } });
    };
    fetchForcedTrainingSession();
  }, [router.query.leadId, appDispatch]);

  useEffect(() => {
    if (shouldScrollToTop && textConversationPageRef.current) {
      // Scroll to the top once the new messages are rendered
      scrollToTop();
      setShouldScrollToTop(false); // Reset to prevent continuous scrolling
    }
  }, [shouldScrollToTop]);

  const {
    dispatch: cbDispatch,
    state: cbState,
    onFileSelected,
    onFilesSelected,
  } = useContext(ChatMessageBoxContext);

  const {
    state,
    showAssignLeadDialog,
    showOptinLeadDialog,
    canViewLead,
    callLead,
    dispatch,
    isInbound,
    isNeedsCall,
  } = leadContext;
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  useRequiresClockIn();

  const scrollToBottom = () => {
    textConversationPageRef.current?.lastElementChild?.scrollIntoView();
  };

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onNewMessage = useCallback(
    (msg: any) => {
      const message = JSON.parse(msg);
      const shouldAppend = shouldAppendMessage(message, state.lead, user);
      if (shouldAppend) {
        dispatch({
          type: "newMessages",
          value: [message],
        });

        util.delay(() => scrollToBottom(), 500);
      }
    },
    [dispatch, user, state.lead]
  );

  const onMessageUpdated = useCallback(
    (msg: any) => {
      const updatedMessage = JSON.parse(msg);
      if (state.lead && state.lead.id && updatedMessage.lead === state.lead.id) {
        dispatch({
          type: "updateMessage",
          value: updatedMessage,
        });
      }
    },
    [dispatch, state.lead]
  );

  const messagesLiveUrl = useMemo(
    () => getMessageLiveURL(state.lead),
    [state.lead]
  );
  useLiveDBRef(messagesLiveUrl, onNewMessage, !state.loading, "child_added");
  useLiveDBRef(messagesLiveUrl, onMessageUpdated, !state.loading, "child_changed");

  const loadInfo = async () => {
    try {
      const { clientId, leadId } = router.query;

      /*Check to make sure that pathname and match url are the same
      the hook changes on the router, but the old params are in there.*/
      if (
        !leadId ||
        !router.location.pathname.match(/\/conversation\//i) ||
        router.pathname !== router.match.url ||
        (leadId === loadingLead.current?.leadId &&
          clientId === loadingLead.current?.clientId)
      ) {
        return;
      }
      loadingLead.current = { clientId, leadId };
      let internalMessages, notesOnly, callsOnly, emailsOnly;
      switch (state.internalMessageFilter) {
        case "notesOnly":
          notesOnly = true;
          break;
        case "internalOnly":
          internalMessages = true;
          break;
        case "callsOnly":
          callsOnly = true;
          break;
        case "emailsOnly":
          emailsOnly = true;
          break;
        case "excludeInternal":
          internalMessages = false;
          break;
      }

      let [
        lead,
        thread,
        surveys,
        clientUsers = appState.clientUsers,
        clientGroups = appState.clientGroups,
        clientHours = appState.clientHours,
        clientMentionUsers = appState.clientMentionUsers,
        leadClient = appState.selectedClient,
      ] = await leadsService.loadConversationData(
        clientId,
        leadId,
        appState.selectedClientId,
        internalMessages,
        notesOnly,
        callsOnly,
        emailsOnly
      );

      const now = moment();
      const dayName = now.format("dddd");
      const hoursToday = clientHours?.find((it: any) => it.weekday === dayName);
      lead.client_hours = "";
      lead.client_hours_today = hoursToday
        ? `${moment(hoursToday.from_hour, "HH:mm:ss").format(
            "h:mma"
          )} - ${moment(hoursToday.to_hour, "HH:mm:ss").format("h:mma")}`
        : "";
      clientHours?.forEach((it: any, index: number) => {
        lead.client_hours += `${it.weekday} ${moment(
          it.from_hour,
          "HH:mm:ss"
        ).format("h:mma")} - ${moment(it.to_hour, "HH:mm:ss").format(
          "h:mma"
        )}\n`;
      });
      leadsService.checkForPauseResets(lead, thread);

      const seenHeaderTooltip = await userDefaults.getValue(
        "seen-chat-header-tooltip"
      );

      //Don't wait for it to load
      pushNotifications.markAllNotifications("read", leadId);
      notifDispatch({
        type: "markLeadRead",
        value: leadId,
      });

      dispatch({
        type: "set",
        value: {
          clientId,
          leadId,
          lead,
          thread: thread.results.reverse(),
          next: thread.next,
          error: false,
          assignDialogDismissed: false,
          surveys: surveys?.results,
          clientHours,
          clientUsers,
          leadClient,
          nextLead: null,
          showHeaderTooltip: !seenHeaderTooltip && window.innerWidth < 992,
          loading: false,
          autoBotEnabled: lead.client_ai_auto_blast_enabled,
        },
      });

      cbDispatch({
        type: "set",
        value: {
          selectedMentionIndex: 0,
          emojiOpen: false,
          showMentionsPopup: false,
          showQuickReplies: false,
          showAllTools: false,
          internalOnly: false,
          allNotificationGroups: [
            ...clientMentionUsers.map((it: any) => ({
              id: it.display_name,
              key: `user_${it.display_name}`,
              name: it.label,
              username: it.display_name,
            })),
            ...clientGroups.map((it: any) => ({
              id: it.id,
              key: `group_${it.id}`,
              name: it.name,
              username: it.name,
            })),
          ],
        },
      });

      if (!native.isNative) {
        util.delay(setMessageFocus, 500);
      }

      //HACK: list adjusts based on variable height content.
      //Need to _actually_ fix
      // util.delay(() => scrollToBottom(), 150);
      // util.delay(() => scrollToBottom(), 750);
    } catch (e: any) {
      if (e.response?.status === 404) {
        router.replace("/text-messages/", {});
        return appNotification.toast(
          "This lead is assigned to someone else.",
          "No Access"
        );
      } else {
        console.error(e);
        dispatch({
          type: "set",
          value: {
            error: true,
            loading: false,
          },
        });
      }
    } finally {
      loadingLead.current = null;
      util.delay(scrollToBottom, 500);
    }
  };

  const fetchAllAndScrollToTop = async () => {
    if (loadingAll) return;

    if (!state.next) {
      // No additional pages to load, scroll to the top immediately
      setShouldScrollToTop(true);
      return;
    }

    loadingIndicator.create();
    setLoadingAll(true);
    try {
      const { clientId, leadId } = router.query;
      const allMessages = await leadsService.getMessages(
        clientId,
        leadId,
        1000
      );

      if (allMessages) {
        dispatch({ type: "set", value: { thread: [] } });

        dispatch({
          type: "appendThread",
          value: allMessages.results,
        });

        dispatch({
          type: "set",
          value: {
            next: allMessages.next,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching all messages: ", error);
    } finally {
      loadingIndicator.dismiss();
      setLoadingAll(false);
      setShouldScrollToTop(true);
    }
  };

  const onLeadChange = () => {
    setUserHasScrolled(false);
    loadInfo();
  };
  // eslint-disable-next-line
  useEffect(onLeadChange, [router, state.internalMessageFilter]);

  const loadNextThread = useCallback(async () => {
    if (loadingAll || !state.next) return;
    try {
      const res = await leadsService.next(state.next);
      dispatch({
        type: "appendThread",
        value: res.results,
      });

      dispatch({
        type: "set",
        value: {
          next: res.next,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, state.next, loadingAll]);

  useOnClickOutside(emojiRef, () =>
    cbDispatch({
      type: "set",
      value: {
        emojiOpen: false,
      },
    })
  );

  useEffect(() => {
    if (!canViewLead(user)) {
      appNotification.toast(
        "This lead is already assigned to someone else.",
        "Lead is Assigned"
      );
      window.location.href = "/text-messages/";
      // using router.replace is causing the component to re-render infinitely
      //router.replace('/text-messages/', {});
    }
  }, [user, router, canViewLead]);

  useEffect(() => showAssignLeadDialog(user), [user, showAssignLeadDialog]);
  useEffect(() => showOptinLeadDialog(user), [user, showOptinLeadDialog]);

  useEffect(() => {
    util.delay(scrollToBottom, 200);
  }, [keyboardOpen]);

  useIonViewDidLeave(() => {
    const leadId = state.lead?.id;
    if ((isInbound || isNeedsCall) && leadId) {
      inboundLeadsService.removeServedLead(leadId);
    }
    setUserHasScrolled(false);
    dispatch({ type: "clear" });
    setIsPageActive(false);
  });

  const isDropSupported = (e: any) =>
    leadsService.isSupportedFileType(
      e.dataTransfer.items[0].type,
      cbState.isEmail
    );
  const onDrop = (e: any) => {
    e.preventDefault();
    const { files } = e.dataTransfer;

    const isVideo = (file: File) =>
      file.type.startsWith("video/") ||
      /\.(mp4|mov|avi|mkv|webm)$/i.test(file.name);

    const handleFiles = (files: File[]) => {
      const videoFile = files.find(isVideo);
      if (videoFile) {
        // If any file is a video, call onFileSelected with the first file
        onFileSelected(videoFile);
      } else {
        // Otherwise, call onFilesSelected with all files
        onFilesSelected(files);
      }
    };

    if (files?.length) {
      handleFiles(Array.from(files));
      return;
    }

    const items = e.dataTransfer.items;
    if (items?.length) {
      const files: File[] = [];
      const promises: Promise<void>[] = [];

      for (let i = 0; i < items.length; i++) {
        const item: any = items[i];
        promises.push(
          new Promise((resolve) => {
            item?.getAsString(async (url: string) => {
              const file = await http.uriToFile(url);
              files.push(file);
              resolve();
            });
          })
        );
      }

      Promise.all(promises).then(() => {
        handleFiles(files);
      });
    }
  };

  const userScrolled = () => setUserHasScrolled(!state.loading);

  const renderChatBubble = (it: any, idx?: number) => {
    if (!state.thread) {
      return null;
    }

    const index = state.thread.indexOf(it);
    const previousItem = index > 0 ? state.thread[index - 1] : null;
    let heading: any = null;
    const sender_user_id = it?.user || null;
    let {
      id,
      message,
      sender_name,
      sent_by_ninja,
      sent_by_1099,
      sms_type,
      smsmedia_set,
      created,
      media,
      outbound_media,
      content_type,
      from_followup,
      read_at,
      gif_url,
      video_thumb,
      text_ninja,
      is_email,
      twilio_status,
      outbound_email_status,
      iframe_tag,
      call,
      resetPause,
      language,
      english_translation,
      sms_blast,
      video_meeting_status,
    } = it;

    // Strip out the media link from the user's view, its confusing
    if (message && /https:\/\/profile\.tecobi\.com.*\/media\/\S*/.test(message)) {
      message = message.replace(
        /https:\/\/profile\.tecobi\.com.*\/media\/\S*/,
        ""
      );
    }
    const format = "dddd, MMMM D, YYYY";
    const mc = moment(created).format(format);
    const pc = previousItem ? moment(previousItem.created).format(format) : "";

    const mediaSet = [].concat(smsmedia_set) as any[];

    if (media && content_type) {
      mediaSet.push({
        id,
        media: media,
        content_type,
      });
    }

    if (outbound_media && Array.isArray(outbound_media)) {
      outbound_media.forEach((item) => {
        if (item.url && item.content_type) {
          mediaSet.push({
            id: item.id, // Use the ID from outbound_media
            media: item.url, // Map the `url` as the media property
            content_type: item.content_type, // Keep the content type
            name: item.name || null, // Optionally include the name
          });
        }
      });
    }

    if (mc !== pc) {
      heading = (
        <IonItemDivider key={mc} sticky>
          {mc}
        </IonItemDivider>
      );
    }

    const outgoing = ["outbound", "system"].indexOf(sms_type) > -1;

    if (from_followup) {
      sender_name = "TECOBI Automations";
    }

    if (!sender_name && !outgoing) {
      sender_name = `${state.lead?.first_name} ${state.lead?.last_name}`;
    }

    const bubble = (
      <div ref={textConversationPageRef}>
        <ChatBubble
          id={id}
          key={`chat-bubble-${id}-${idx}`}
          senderId={sender_user_id}
          isEmail={is_email}
          mediaSet={mediaSet}
          message={message}
          sender={sender_name}
          smsBlast={sms_blast}
          ninja={sent_by_ninja}
          senderIs1099={sent_by_1099}
          read={!!read_at}
          adPreview={iframe_tag}
          call={call}
          videoThumb={video_thumb}
          gif={gif_url}
          translation={english_translation}
          videoMeetingStatus={video_meeting_status}
          language={language}
          dateSent={util.formatMessageDate(created)}
          outgoing={outgoing}
          yellow={!!text_ninja}
          beige={!text_ninja && is_email}
          orange={
            !!text_ninja &&
            (message.includes("needs a call") ||
              message.includes("Needs call set to true"))
          }
          red={!!text_ninja && message.includes("**Message not sent")}
          internal={!!text_ninja}
          resetPause={resetPause}
          twilioStatus={twilio_status}
          outboundEmailStatus={
            user.is_staff ? outbound_email_status : undefined
          }
        />
      </div>
    );
    return heading ? [heading, bubble] : bubble;
  };

  return (
    <TInfiniteScrollPage
      scrollToTopRefElement={<div ref={topRef} />}
      contentId='conversationContent'
      loading={state.loading}
      className='text-message-thread'
      onUserScroll={userScrolled}
      toolbar={<LeadDuplicates onMerged={loadInfo} />}
      customHeaderContent={<ChatHeader />}
      customFooterContent={isPageActive ? <ChatFooter /> : undefined} // Only render ChatFooter if page is active
      headerTool={
        <IonButton onClick={callLead} fill='clear' slot='end'>
          <IonIcon slot='icon-only' icon={call} />
        </IonButton>
      }
      fab={
        <TextMessageConversationFab
          handleScrollToTop={fetchAllAndScrollToTop}
        />
      }
      items={state.thread ?? []}
      position='top'
      hasNext={!state.loading && !!state.next}
      error={state.error}
      isDropSupported={isDropSupported}
      onDrop={onDrop}
      contentChildren={
        state.lead?.id && (
          <LeadTypingUsers
            leadId={state.lead?.id}
            leadFullName={`${state.lead?.first_name} ${state.lead?.last_name}`}
            clientId={state.lead?.client}
            clientName={state.lead?.client_name}
            userHasScrolled={userHasScrolled}
          />
        )
      }
      onLoadNextPage={loadNextThread}
      renderItem={renderChatBubble}
    >
      {!native.isNative && isPageActive && (
        <EmojiPicker
          ref={emojiRef}
          isOpen={cbState.emojiOpen}
          onSelected={appendMessageText}
        />
      )}
      {cbState.showMentionsPopup && <ChatMentionsHelper />}
      {!state.loading && state.showHeaderTooltip && <ChatProfileTooltip />}
      {state.showFacebookAd && isPageActive && (
        <FacebookAdPreviewModal
          isOpen={true}
          onDidDismiss={() =>
            dispatch({
              type: "set",
              value: { showFacebookAd: false },
            })
          }
        />
      )}
      {state.showPauseModal && isPageActive && (
        <PauseModal
          isOpen={true}
          onDidDismiss={() =>
            dispatch({
              type: "set",
              value: { showPauseModal: false },
            })
          }
        />
      )}

      {state.mediaPreview && isPageActive && (
        <MediaPreviewModal
          isOpen={true}
          onDidDismiss={() =>
            dispatch({ type: "set", value: { mediaPreview: null } })
          }
        />
      )}

      {cbState.showQuickReplies && isPageActive && (
        <QuickRepliesModal
          isOpen={true}
          onDidDismiss={() =>
            cbDispatch({
              type: "set",
              value: { showQuickReplies: false },
            })
          }
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default TextMessageConversationContent;
