import React, { useContext, useState, useEffect } from 'react';
import { LeadContext } from '../../context/LeadContext';
import { IonList, IonButton, IonBadge } from '@ionic/react';
import TDateTimeItem from '../TDateTimeItem';
import '../../styles/components/LeadFilters.scss';
import TSelectItem from '../TSelectItem';
import ButtonGrid from '../ButtonGrid';
import { modalController } from '@ionic/core';
import { haptics } from '../../core';
import { AppContext } from '../../context/AppContext';
import {
  briefcase,
  archive,
  eye,
  globe,
  flame,
  checkmarkCircleOutline,
  call,
  pause,
  personCircle,
  paperPlane,
  funnel,
  man,
  key as carKey,
  card,
  carSport,
  calendar,
  ban,
  phonePortrait,
  megaphone,
} from 'ionicons/icons';
import { TModal } from '.';
import { leadsService } from '../../services';

const LeadFilters: React.FC = () => {
  const appContext = useContext(AppContext);
  const { user, leadSorting, selectedClientId, languages } = appContext.state;

  const classificationOptions = ['All', 'Sales', 'Service', 'Employment', 'Collections'];

  if (user?.is_staff) {
    classificationOptions.splice(3, 0, 'Service Canceled');
  }

  const { state, dispatch, resetFilters } = useContext(LeadContext);
  const { filters } = state;
  const [withoutHumanInteractionCount, setWithoutHumanInteractionCount] =
    useState(0);
  const [changes, setChanges] = useState<any>();
  const [createdAtTo, setCreatedAtTo] = useState<string>(
    filters.created_at__lte
  );
  const [createdAtFrom, setCreatedAtFrom] = useState<string>(
    filters.created_at__gte
  );
  const [lastMessageTo, setLastMessageTo] = useState<string>(
    filters.last_message__lte
  );
  const [lastMessageFrom, setLastMessageFrom] = useState<string>(
    filters.last_message__gte
  );
  const [lastLeadSubmissionFrom, setLastLeadSubmissionFrom] = useState<string>(
    filters.last_lead_submitted_actual__gte
  );
  const [lastLeadSubmissionTo, setLastLeadSubmissionTo] = useState<string>(
    filters.last_lead_submitted_actual__lte
  );
  const [updatedSorting, setUpdatedSorting] = useState(leadSorting);
  const [statusIds, setStatusIds] = useState<(string | number)[]>(filters.status?.id?.split(',') || []);

  useEffect(() => {
    setCreatedAtTo(filters?.created_at__lte);
    setCreatedAtFrom(filters?.created_at__gte);
    setLastMessageTo(filters?.last_message__lte);
    setLastMessageFrom(filters?.last_message__gte);
    setLastLeadSubmissionFrom(filters?.last_lead_submitted_actual__gte);
    setLastLeadSubmissionTo(filters?.last_lead_submitted_actual__lte);
    return setChanges(filters);
  }, [filters]);

  useEffect(() => {
    if (!state.showLeadFilters) {
      return;
    }

    (async () => {
      try {
        const count = await leadsService.getCountWithoutHumanInteraction(
          selectedClientId
        );

        setWithoutHumanInteractionCount(count);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [selectedClientId, state.showLeadFilters]);

  useEffect(() => {
    if (!filters.status__in) {
      setStatusIds(['All'])
    }
  }, [filters])

  const onValueChange =
    (key: string, display?: (id: any) => any, allDefault?: boolean) =>
      (e: CustomEvent) => {
        const value = e.detail.value;

        setChanges((f: any) => {
          if (Array.isArray(value)) {
            if (value.includes('All') && value.length === 1) {
              // If "All" is selected, clear status__in from filters and set default value to "All"
              delete f[key];
              setStatusIds(['All']);
            } else if (value.length === 0) {
              delete f[key];
              setStatusIds(['All']);
            } else {
              // If other selections are made, remove "All" from the selections
              const ids = value.filter((val) => val !== 'All');
              const displayText = ids.map((id) => (display ? display(id) ?? 'Unknown' : id)).join(', ');

              f[key] = { id: ids.join(','), display: displayText };
              setStatusIds(ids);
            }
          } else {
            if (!allDefault || value !== 'All') {
              if (display) {
                let val: any = parseInt(value);
                val = isNaN(val) ? value : val;
                f[key] = { id: val, display: display(val) ?? 'Unknown' };
              } else {
                f[key] = value;
                if (key === 'created_at__gte') setCreatedAtFrom(value);
                if (key === 'created_at__lte') setCreatedAtTo(value);
                if (key === 'last_message__gte') setLastMessageFrom(value);
                if (key === 'last_message__lte') setLastMessageTo(value);
                if (key === 'last_lead_submitted_actual__gte') setLastLeadSubmissionFrom(value);
                if (key === 'last_lead_submitted_actual__lte') setLastLeadSubmissionTo(value);
              }
            }
          }
          return f;
        });
      };

  return (
    <TModal
      className='lead-filters'
      mode="md"
      isOpen={state.showLeadFilters}
      onDidDismiss={() =>
        dispatch({
          type: 'set',
          value: { showLeadFilters: false },
        })
      }
      title='Filters'
      footer={
        <ButtonGrid>
          <IonButton
            expand='block'
            color='primary'
            onClick={() => {
              haptics.lightImpact();
              modalController.dismiss();
              resetFilters();
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand='block'
            color='secondary'
            onClick={() => {
              haptics.lightImpact();
              modalController.dismiss();
              dispatch({
                type: 'set',
                value: {
                  filters: { ...changes },
                  showLeadFilters: false,
                },
              });

              appContext.dispatch({
                type: 'set',
                value: {
                  leadSorting: updatedSorting,
                },
              });
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList lines='full'>
        <TDateTimeItem
          label='Lead Created'
          range
          toValue={createdAtTo}
          fromValue={createdAtFrom}
          onFromIonChange={onValueChange('created_at__gte')}
          onToIonChange={onValueChange('created_at__lte')}
        />
        <TDateTimeItem
          label='Last Message'
          range
          toValue={lastMessageTo}
          fromValue={lastMessageFrom}
          onFromIonChange={onValueChange('last_message__gte')}
          onToIonChange={onValueChange('last_message__lte')}
        />
        <TDateTimeItem
          label="Last Lead Submission"
          range
          toValue={lastLeadSubmissionFrom}
          fromValue={lastLeadSubmissionTo}
          onFromIonChange={onValueChange('last_lead_submitted_actual__gte')}
          onToIonChange={onValueChange('last_lead_submitted_actual__lte')}
        />
        <TSelectItem
          label="No Blast"
          icon={megaphone}
          options={['All', 'true', 'false']}
          value={changes?.no_blast ?? 'All'}
          onChange={onValueChange('no_blast', undefined, false)}
        />
        {!user?.is_text_ninja && (user?.is_client_admin || user?.is_admin) && (
          <TSelectItem
            label='Watching'
            value={changes?.watchers ?? 'All'}
            onChange={onValueChange(
              'watchers',
              (id: any) =>
                appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                  ?.full_name ?? id,
              true
            )}
            options={[
              { value: 'All', text: 'All' },
              { value: user?.id, text: 'My Watch List' },
            ]}
            icon={eye}
          />
        )}
        <TSelectItem
          label='Language'
          value={changes?.language ?? 'All'}
          onChange={onValueChange(
            'language',
            (id) => languages?.find((lang) => lang.id === id)?.name,
            true
          )}
          options={[
            { value: 'All', text: 'All' },
            ...languages?.map?.((it) => ({
              value: it.id,
              text: it.name,
            })),
          ]}
          icon={globe}
        />
        <TSelectItem
          label='Carrier Type'
          value={changes?.carrier_type ?? 'All'}
          onChange={onValueChange('carrier_type', undefined, false)}
          options={['All', 'mobile', 'landline', 'voip']}
          icon={phonePortrait}
        />
        <TSelectItem
          label='Violators'
          value={changes?.violators ?? 'All'}
          onChange={onValueChange('violators', undefined, false)}
          options={['All', 'true', 'false']}
          icon={ban}
        />
        <TSelectItem
          label='Classification'
          value={changes?.classification ?? 'All'}
          onChange={onValueChange('classification', undefined, false)}
          options={classificationOptions}
          icon={briefcase}
        />
        <TSelectItem
          label='Archived Leads'
          value={changes?.convo_archived ?? 'All'}
          onChange={onValueChange('convo_archived', undefined, false)}
          options={['Active', 'Archived', 'All']}
          icon={archive}
        />
        <TSelectItem
          label='Needs Call'
          value={changes?.needs_call ?? 'All'}
          onChange={onValueChange('needs_call', undefined, false)}
          options={['Call Not Needed', 'Call Needed', 'All']}
          icon={flame}
        />
        <TSelectItem
          label='Human Contact'
          value={changes?.human ?? 'All'}
          onChange={onValueChange('human')}
          options={['All', 'Without', 'With']}
          icon={man}
        >
          {withoutHumanInteractionCount > 0 && (
            <IonBadge color='danger' mode='ios'>
              {withoutHumanInteractionCount}
            </IonBadge>
          )}
        </TSelectItem>

        <TSelectItem
          label='Lead Status'
          multiple
          value={statusIds}
          onChange={onValueChange(
            'status__in',
            (id: any) =>
              appContext.state.statuses?.find?.((it: any) => it.id === id)
                ?.option_name,
            true
          )}
          options={[
            { value: 'All', text: 'All' },
            ...appContext.state.statuses?.map?.((it) => ({
              value: it.id,
              text: it.option_name,
            })),
          ]}
          icon={checkmarkCircleOutline}
        />
        <TSelectItem
          label='Phone Number'
          value={changes?.twilio_number?.id ?? 'All'}
          onChange={onValueChange(
            'twilio_number',
            (id: any) =>
              appContext.state.clientNumbers?.find?.((it: any) => it.id === id)
                ?.script?.name,
            true
          )}
          options={[
            { value: 'All', text: 'All' },
            ...appContext.state.clientNumbers?.map?.((it) => ({
              value: it.id,
              text: it.script.name,
            })),
          ]}
          icon={call}
        />
        <TSelectItem
          label='Pause'
          defaultValue='All'
          value={changes?.pause_date ?? 'All'}
          onChange={onValueChange('pause_date', undefined, true)}
          options={['All', 'Paused', 'Unpaused']}
          icon={pause}
        />
        <TSelectItem
          label='Imported/Replied'
          value={changes?.imported_replied__in ?? 'All'}
          onChange={onValueChange('imported_replied__in', undefined, true)}
          options={[
            { value: 'All', text: 'All' },
            { value: 'null', text: 'Not Imported' },
            { value: 'true,false', text: 'Imported' },
            { value: 'true', text: 'Imported + Inbound' },
            { value: 'false', text: 'Imported + No-Inbound' },
          ]}
          icon={call}
        />
        <TSelectItem
          label='Salesperson 1'
          value={changes?.assigned_to?.id ?? 'All'}
          onChange={onValueChange(
            'assigned_to',
            (id: any) =>
              appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                ?.full_name ?? id,
            true
          )}
          icon={personCircle}
          options={[
            { value: 'All', text: 'All' },
            { value: user?.id, text: 'Me' },
            { value: 'Unassigned', text: 'Unassigned' },
            ...appContext.state.clientUsers
              ?.filter((it) => it.id !== user?.id)
              ?.map?.((it) => ({
                value: it.id,
                text: it.full_name,
              })),
          ]}
        />
        <TSelectItem
          label="Salesperson 2"
          value={changes?.secondary_rep?.id ?? 'All'}
          onChange={onValueChange(
            'secondary_rep',
            (id: any) =>
              appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                ?.full_name ?? id,
            true
          )}
          icon={personCircle}
          options={[
            { value: 'All', text: 'All' },
            { value: user?.id, text: 'Me' },
            { value: 'Unassigned', text: 'Unassigned' },
            ...appContext.state.clientUsers
              ?.filter((it) => it.id !== user?.id)
              ?.map?.((it) => ({
                value: it.id,
                text: it.full_name,
              })),
          ]}
        />
        <TSelectItem
          label="Finance Manager"
          value={changes?.finance_manager?.id ?? 'All'}
          onChange={onValueChange(
            'finance_manager',
            (id: any) =>
              appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                ?.full_name ?? id,
            true
          )}
          icon={personCircle}
          options={[
            { value: 'All', text: 'All' },
            { value: user?.id, text: 'Me' },
            { value: 'Unassigned', text: 'Unassigned' },
            ...appContext.state.clientUsers
              ?.filter((it) => it.id !== user?.id)
              ?.map?.((it) => ({
                value: it.id,
                text: it.full_name,
              })),
          ]}
        />
        <TSelectItem
          label='Sales Manager'
          value={changes?.sales_manager?.id ?? 'All'}
          onChange={onValueChange(
            'sales_manager',
            (id: any) =>
              appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                ?.full_name ?? id,
            true
          )}
          icon={personCircle}
          options={[
            { value: 'All', text: 'All' },
            { value: user?.id, text: 'Me' },
            { value: 'Unassigned', text: 'Unassigned' },
            ...appContext.state.clientUsers
              ?.filter((it) => it.id !== user?.id)
              ?.map?.((it) => ({
                value: it.id,
                text: it.full_name,
              })),
          ]}
        />
        <TSelectItem
          label='BDC Rep'
          value={changes?.bdc_rep?.id ?? 'All'}
          onChange={onValueChange(
            'bdc_rep',
            (id: any) =>
              appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                ?.full_name ?? id,
            true
          )}
          icon={personCircle}
          options={[
            { value: 'All', text: 'All' },
            { value: user?.id, text: 'Me' },
            { value: 'Unassigned', text: 'Unassigned' },
            ...appContext.state.clientUsers
              ?.filter((it) => it.id !== user?.id)
              ?.map?.((it) => ({
                value: it.id,
                text: it.full_name,
              })),
          ]}
        />
        <TSelectItem
          label="Service Rep"
          value={changes?.service_rep?.id ?? 'All'}
          onChange={onValueChange(
            'service_rep',
            (id: any) =>
              appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                ?.full_name ?? id,
            true
          )}
          icon={personCircle}
          options={[
            { value: 'All', text: 'All' },
            { value: user?.id, text: 'Me' },
            { value: 'Unassigned', text: 'Unassigned' },
            ...appContext.state.clientUsers
              ?.filter((it) => it.id !== user?.id)
              ?.map?.((it) => ({
                value: it.id,
                text: it.full_name,
              })),
          ]}
        />
        <TSelectItem
          label='Parts Rep'
          value={changes?.parts_rep?.id ?? 'All'}
          onChange={onValueChange(
            'parts_rep',
            (id: any) =>
              appContext.state.clientUsers?.find?.((it: any) => it.id === id)
                ?.full_name ?? id,
            true
          )}
          icon={personCircle}
          options={[
            { value: 'All', text: 'All' },
            { value: user?.id, text: 'Me' },
            { value: 'Unassigned', text: 'Unassigned' },
            ...appContext.state.clientUsers
              ?.filter((it) => it.id !== user?.id)
              ?.map?.((it) => ({
                value: it.id,
                text: it.full_name,
              })),
          ]}
        />
        <TSelectItem
          label='Lead Provider'
          value={changes?.master_provider?.id ?? 'All'}
          onChange={onValueChange(
            'master_provider',
            (id: any) =>
              appContext.state.clientProviders?.find?.(
                (it: any) => it.id === id
              )?.name,
            true
          )}
          options={[
            { value: 'All', text: 'All' },
            ...appContext.state.clientProviders?.map?.((it) => ({
              value: it.id,
              text: it.name,
            })),
          ]}
          icon={paperPlane}
        />
        <TSelectItem
          label='Trade Stage'
          options={[
            { value: '', text: 'Any' },
            'needs',
            'has',
            'no-need',
            'has/no-need',
          ]}
          value={changes?.trade_stage ?? ''}
          onChange={onValueChange('trade_stage', undefined, true)}
          icon={carKey}
        />
        <TSelectItem
          label='Credit Stage'
          options={[
            { value: '', text: 'Any' },
            'needs',
            'has',
            'no-need',
            'has/no-need',
          ]}
          value={changes?.credit_stage ?? ''}
          onChange={onValueChange('credit_stage', undefined, true)}
          icon={card}
        />
        <TSelectItem
          label='Vehicle Stage'
          options={[{ value: '', text: 'Any' }, 'needs', 'has']}
          value={changes?.vehicle_stage ?? ''}
          onChange={onValueChange('vehicle_stage', undefined, true)}
          icon={carSport}
        />
        <TSelectItem
          label='Appointment Stage'
          options={[{ value: '', text: 'Any' }, 'needs', 'has']}
          value={changes?.appt_stage ?? ''}
          onChange={onValueChange('appt_stage', undefined, true)}
          icon={calendar}
        />
        <TSelectItem
          label='Sort'
          icon={funnel}
          value={updatedSorting ?? 'Newest First'}
          options={['Newest First', 'Oldest First']}
          onChange={(e: CustomEvent) => setUpdatedSorting(e.detail.value)}
        />
      </IonList>
    </TModal>
  );
};

export default LeadFilters;
