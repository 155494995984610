import React, { useEffect } from "react";

interface SiteTranslatorProps {
  id: string;
  pageLanguage?: string;
  includedLanguages?: string;
}

const SiteTranslator: React.FC<SiteTranslatorProps> = ({
  id,
  pageLanguage = "en",
  includedLanguages = "",
}) => {
  useEffect(() => {
    const scriptId = "google-translate-script";

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
    }

    (window as any).googleTranslateElementInit = () => {
      new (window as any).google.translate.TranslateElement(
        {
          pageLanguage,
          includedLanguages,
          layout: (window as any).google.translate.TranslateElement.InlineLayout
            .HORIZONTAL,
        },
        id
      );
    };
  }, [id, pageLanguage, includedLanguages]);

  return <div id={id} />;
};

export default SiteTranslator;
