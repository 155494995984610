import util from "./util";
import axios from "axios";
// import { Plugins } from "@capacitor/core";
import native from "./native";
import {Preferences} from "@capacitor/preferences"
// const { Storage } = Plugins;
import jwtDecode from 'jwt-decode';
import appConfig from '../config.json';
import environment from './environment';
import { dispatch } from '../context/AppContext';

class Authorization {
  private authKey = "authorizationToken";
  private refreshTokenKey = "refreshTokenKey";
  private clientKey = "clientId";
  private baseUrl: string;

  constructor() {
    const envUrlType = environment.apiEnvironment;
    const APIs = appConfig.tecobi.api;

    switch (envUrlType) {
      case 'development':
        this.baseUrl = APIs.development.baseUrl;
        break;
      case 'staging':
        this.baseUrl = APIs.staging.baseUrl;
        break;
      default:
        this.baseUrl = APIs.production.baseUrl;
    }
  }

  async setToken(token: string, refreshToken: string) {
    await Preferences.set({ key: this.authKey, value: token });
    await Preferences.set({ key: this.refreshTokenKey, value: refreshToken });
  }

  async getToken() {
    const { value } = await Preferences.get({ key: this.authKey });
    return value;
  }

  async getRefreshToken() {
    const { value } = await Preferences.get({ key: this.refreshTokenKey });
    return value;
  }

  decodeToken(token: string) {
    return jwtDecode(token);
  }

  isTokenExpired(token: string) {
    const decoded: any = this.decodeToken(token);
    const expirationDate = new Date(decoded.exp * 1000);
    return expirationDate < new Date();
  }

  async refreshToken() {
    const refreshToken = await this.getRefreshToken();

    if (!refreshToken) {
      throw new Error("Refresh token not found.");
    }

    try {
      // Call refresh API endpoint.
      const res = await axios.post(`${this.baseUrl}/token/refresh/`, {
        refresh: refreshToken
      });
      // Extract the new access token from the response
      const newToken = res.data.access;
      // We have sliding session (where the refresh token's expiry gets extended with activity)
      const { refresh } = res.data;
      // Save the new tokens back to the authorization module
      // so they can be used for future requests.
      await this.setToken(newToken, refresh);

      // Return the new token
      return newToken;
    } catch (e : any) {
      let res = e.response || {};
      if (res.status === 401) {
        dispatch({ type: 'logout' });
        this.clear();
        window.location.reload();
        return;
      }
    }
  }

  async getSelectedClientId() {
    const sessionClientId = window.sessionStorage.getItem(this.clientKey);
    const { value } = await Preferences.get({ key: this.clientKey });

    if (!native.isNative && sessionClientId) {
      return parseInt(sessionClientId);
    }

    if (!native.isNative && value) {
      window.sessionStorage.setItem(this.clientKey, value);
    }
    return value ? parseInt(value) : null;
  }

  async clearSelectedClientId() {
    if (!native.isNative) {
      window.sessionStorage.removeItem(this.clientKey);
    }
    await Preferences.remove({ key: this.clientKey });
  }

  async setSelectedClientId(id: number) {
    if (!native.isNative) {
      window.sessionStorage.setItem(this.clientKey, id.toString());
    }

    await Preferences.set({ key: this.clientKey, value: id.toString() });
    util.defer(() =>
      window.dispatchEvent(new CustomEvent("client:changed", { detail: id }))
    );
  }

  async hasSelectedClientId() {
    return !!(await this.getSelectedClientId());
  }

  async clearToken() {
    await Preferences.remove({ key: this.authKey });
    await Preferences.remove({ key: this.refreshTokenKey });
  }

  async isAuthorized() {
    return !!(await this.getToken());
  }

  async clear() {
    localStorage.clear();
    return await Preferences.clear();
  }

  async load() {
    const authorizationToken = await this.getToken();

    return {
      authorized: !!authorizationToken,
      authorizationToken,
    };
  }
}

const authorization = new Authorization();
export default authorization;
