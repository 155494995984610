import React, { useState, useMemo, useContext, useCallback } from 'react';
import {
  TInfiniteScrollPage,
  TSearchBar,
  QuickQualificationRow
} from '../components';
import { IonItemDivider, IonIcon, IonText, IonButton } from '@ionic/react';
import { quickQualificationsService } from '../services';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';
import { util } from '../core';
import { megaphone } from 'ionicons/icons';

const QuickQualificationsPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const { selectedClient } = state;
  const [search, setSearch] = useState<string | null>(null);
  const isEnabled = useMemo(() => selectedClient.soft_pulls, [
    selectedClient
  ]);
  const showUpgradePage = !isEnabled;

  const loadQuickQualifications = useCallback(async () => {
    if (isEnabled) {
      return await quickQualificationsService.list({
        clientId: state.selectedClientId,
        search
      });
    }
  }, [state.selectedClientId, search, isEnabled]);

  const { next, error, loadNext, loading, data, refresher } = useDataLoader(
    loadQuickQualifications,
    true
  );

  return (
    <TInfiniteScrollPage
      preContent={
        showUpgradePage && (
          <div className="blasts-upgrade-container">
            <div className="blasts-upgrade-icon-container">
              <IonIcon icon={megaphone} className="blasts-upgrade-icon" />
              <IonText>
                <h4>Want to offer soft pulls to your leads?</h4>
                <IonText className="detail">
                  Contact your performance manager
                  {selectedClient?.performance_manager
                    ? ` ${selectedClient.performance_manager.full_name}`
                    : ''}{' '}
                  today to have them enable soft pulls for you.
                </IonText>
              </IonText>
            </div>
            {selectedClient?.performance_manager && (
              <IonButton
                color="secondary"
                onClick={async () =>
                  util.tel(selectedClient.performance_manager.cell_phone)
                }
              >
                Contact {selectedClient.performance_manager.full_name}
              </IonButton>
            )}
          </div>
        )
      }
      onLoadNextPage={loadNext}
      hasNext={!!next}
      toolbarLoadingIndicator={!!search && loading}
      onRefresh={refresher}
      toolbar={
        <IonItemDivider className="no-padding no-margin">
          <TSearchBar
            onSearch={(value: string) => {
              setSearch(value);
            }}
            style={{ width: '100%' }}
            placeholder="Search Soft Pulls"
          />
        </IonItemDivider>
      }
      zeroStateText={
        search ? 'No Soft Pulls Found Matching Criteria' : 'No Soft Pulls'
      }
      renderItem={(item: any) => (
        <QuickQualificationRow key={item.id} item={item} />
      )}
      items={data}
      loading={loading}
      error={error}
    />
  );
};

export default QuickQualificationsPage;
