import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonSpinner,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import { addOutline, trashOutline } from "ionicons/icons";
import TExpandableItemGroup from "./TExpandableItemGroup";
import { leadVehiclesInterestService } from "../services";
import { LeadContext } from "../context/LeadContext";
import AddVehicleModal from "./modals/AddVehicleModal"; // New modal
import TButtonItem from "./TButtonItem";

const LeadVehiclesInterest: React.FC = () => {
  const { state } = useContext(LeadContext);
  const { lead } = state;
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(
    null
  );

  const clientId = lead?.client;
  const leadId = lead?.id;

  useEffect(() => {
    if (!clientId || !leadId) return;

    const fetchVehicles = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await leadVehiclesInterestService.list(clientId, leadId);
        setVehicles(data || []);
      } catch (err: any) {
        setError(err.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [clientId, leadId]);

  const handleVehicleAdded = (newVehicle: any) => {
    setVehicles([...vehicles, newVehicle]);
  };

  const confirmDeleteVehicle = (vehicleId: number) => {
    setSelectedVehicleId(vehicleId);
    setShowConfirm(true);
  };

  const handleDeleteVehicle = async () => {
    if (!selectedVehicleId || !clientId || !leadId) return;

    try {
      await leadVehiclesInterestService.delete(
        clientId,
        leadId,
        selectedVehicleId
      );
      setVehicles((prev) => prev.filter((v) => v.id !== selectedVehicleId));
      setShowConfirm(false);
      setSelectedVehicleId(null);
    } catch (e) {
      console.error("Error deleting vehicle:", e);
    }
  };

  if (!lead) return null;

  return (
    <TExpandableItemGroup title='Vehicles of Interest' badge={vehicles?.length}>
      <TButtonItem color='secondary' onClick={() => setShowModal(true)}>
        <IonIcon icon={addOutline} slot='start' />
        Add Vehicle
      </TButtonItem>

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <IonSpinner name='crescent' />
          <IonItem>Loading vehicles...</IonItem>
        </div>
      ) : error ? (
        <IonItem color='danger'>Error: {error}</IonItem>
      ) : vehicles.length === 0 ? (
        <IonItem>No vehicles of interest for this lead.</IonItem>
      ) : (
        <IonList>
          {vehicles.map((vehicle, index) => (
            <IonItem key={index}>
              <IonLabel>
                <h2>
                  {vehicle.year} {vehicle.make} {vehicle.model} {vehicle.trim}
                </h2>
                <p>
                  <strong>Interest:</strong> {vehicle.interest || "N/A"}
                </p>
                <p>
                  <strong>Stock:</strong> {vehicle.stock || "N/A"}
                </p>
                <p>
                  <strong>VIN:</strong> {vehicle.vin || "N/A"}
                </p>
                <p>
                  <strong>Condition:</strong> {vehicle.condition || "N/A"}
                </p>
                <p>
                  <strong>Price:</strong> ${vehicle.price || "N/A"}
                </p>
                <p>
                  <strong>Odometer:</strong> {vehicle.odometer || "N/A"} miles
                </p>
              </IonLabel>
              <IonButton
                color='danger'
                fill='clear'
                onClick={() => confirmDeleteVehicle(vehicle.id)}
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      )}

      {!!leadId && !!clientId && (
        <AddVehicleModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          clientId={clientId}
          leadId={leadId}
          onVehicleAdded={handleVehicleAdded}
        />
      )}

      {/* Delete Confirmation Alert */}
      <IonAlert
        isOpen={showConfirm}
        header='Delete Vehicle'
        message='Are you sure you want to delete this vehicle of interest?'
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setShowConfirm(false),
          },
          {
            text: "Confirm",
            role: "confirm",
            handler: handleDeleteVehicle,
          },
        ]}
        onDidDismiss={() => setShowConfirm(false)}
      />
    </TExpandableItemGroup>
  );
};

export default LeadVehiclesInterest;
