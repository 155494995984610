import React, { useContext, useEffect, useCallback, useState } from 'react';
import { ClientUser } from '../types/ClientUser';
import {
  TPage,
  TItemGroup,
  TToggleItem,
  TInputItem,
  TSelectItem,
  HapticButton,
  TAvatar,
} from '../components';
import {
  IonList,
  IonChip,
  IonLabel,
  IonIcon,
  IonGrid,
  IonItem,
  IonRow,
  IonCol,
  IonButton,
} from '@ionic/react';
import useRequireSettingsAdmin from '../hooks/useRequireSettingsAdmin';
import { useParams, useHistory } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import {
  clientUsersService,
  clientGroupsService,
  clientService
} from '../services';
import { appNotification, http, util, loadingIndicator, media } from '../core';
import {
  people,
  camera,
  closeCircle,
  save
} from 'ionicons/icons';
import deepEqual from 'deep-equal';
import '../styles/pages/EditClientUser.scss';

export interface RouteParams {
  id: string;
  clientId: string;
}

const EditClientUserPage: React.FC = () => {
  const params = useParams<RouteParams>();
  const clientId = +params.clientId;
  const id = +params.id;
  const [mask, setMask] = useState<string>('');
  const { state, dispatch } = useContext(AppContext);
  const { selectedClientId, selectedClient, clientUsers, clientGroups } = state;
  const history = useHistory();
  const { jobTitles, user: loggedInUser } = state;
  const [showAllFields, setShowAllFields] = useState(!!id);
  const [userExists, setUserExists] = useState(false);
  const [user, setUser] = useState<ClientUser | undefined>();
  const [groups, setGroups] = useState<any>();
  const [client, setClient] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<any>();
  const [photoPreview, setPhotoPreview] = useState<any>();
  const [introVideo, setIntroVideo] = useState<any>();
  const [introVideoPreview, setIntroVideoPreview] = useState<any>();
  const [coverPhoto, setCoverPhoto] = useState<any>();
  const [coverPhotoPreview, setCoverPhotoPreview] = useState<any>();
  const isCreate = !id;
  const handleMediaSelection = async (file: any, setPreview: any, setFile: any) => {
    return new Promise<void>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPreview(reader.result);
        setFile(file);
        resolve();
      });
      reader.readAsDataURL(file);
    });
  };

  const saveMedia = async (mediaType: string) => {
    if (!user?.id || !mediaType) return;
  
    const file =
      mediaType === 'profile_image' ? photo :
      mediaType === 'intro_video' ? introVideo :
      mediaType === 'cover_photo' ? coverPhoto : null;
  
    if (!file) return;
  
    const formData = new FormData();
    formData.append(mediaType, file);
  
    try {
      const updatedUser = await clientUsersService.uploadMedia(clientId, user.id, formData);
      setUser(updatedUser);
      if (mediaType === 'profile_image') {
        setPhoto(updatedUser.profile_image);
        setPhotoPreview(undefined);
      } else if (mediaType === 'intro_video') {
        setIntroVideo(updatedUser.intro_video);
        setIntroVideoPreview(undefined);
      } else if (mediaType === 'cover_photo') {
        setCoverPhoto(updatedUser.cover_photo);
        setCoverPhotoPreview(undefined);
      }
      appNotification.toast(`${mediaType.replace('_', ' ')} updated successfully.`, 'Success');
    } catch (error) {
      console.error(`Failed to upload ${mediaType}`, error);
      appNotification.toast(`Failed to upload ${mediaType}.`, 'Error');
    }
  };
  
  const removeMedia = async (mediaType: string) => {
    if (!user?.id || !mediaType) return;
  
    try {
      const formData = new FormData();
      formData.append(mediaType, '');
      const updatedUser = await clientUsersService.uploadMedia(clientId, user.id, formData);
      setUser(updatedUser);
      if (mediaType === 'profile_image') {
        setPhoto(undefined);
        setPhotoPreview(undefined);
      } else if (mediaType === 'intro_video') {
        setIntroVideo(undefined);
        setIntroVideoPreview(undefined);
      } else if (mediaType === 'cover_photo') {
        setCoverPhoto(undefined);
        setCoverPhotoPreview(undefined);
      }
      appNotification.toast(`${mediaType.replace('_', ' ')} removed successfully.`, 'Success');
    } catch (error) {
      console.error(`Failed to remove ${mediaType}`, error);
      appNotification.toast(`Failed to remove ${mediaType}.`, 'Error');
    }
  };

  const notifications = [
    {
      label: 'New Lead',
      value: 'notification_preference'
    },
    {
      label: 'New Message',
      value: 'message_notification_preference'
    },
    {
      label: 'Tags',
      value: 'tag_notification_preference'
    },
    {
      label: 'Daily Reports',
      value: 'daily_report_notifications'
    },
    {
      label: 'Monthly Reports',
      value: 'monthly_report_notifications'
    }
  ];

  const loadUser = useCallback(async () => {
    if (clientId === selectedClientId) {
      const u = clientUsers.find((u: ClientUser) => u.id === id);
      if (u) {
        setGroups(clientGroups);
        setClient(selectedClient);
        setUser(u);
        setPhoto(u.profile_image);
        setIntroVideo(u.intro_video);
        setCoverPhoto(u.cover_photo);
      }
    }
    try {
      const [u, g, c] = await Promise.all([
        id ? clientUsersService.get(clientId, id) : async () => ({}),
        clientGroupsService.request(clientId),
        clientService.request(clientId)
      ]);
      setUser(u);
      setPhoto(u.profile_image);
      setIntroVideo(u.intro_video);
      setCoverPhoto(u.cover_photo);
      setGroups(g);
      setClient(c);
      setLoading(false);
    } catch (e) {
      http.onHttpError(e);
    }
  }, [
    clientId,
    id,
    selectedClientId,
    clientUsers,
    clientGroups,
    selectedClient
  ]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const refreshClientUsers = async () => {
    try {
      const res = await clientUsersService.getList(clientId);
      dispatch({
        type: 'set',
        value: { clientUsers: res }
      });
    } finally {
    }
  };

  const createUser = async () => {
    if (userExists) {
      return appNotification.alertSimple('User already exists.', 'User Exists');
    }

    if (!user?.email) {
      return appNotification.toast(
        'Please enter in a valid email address.',
        'Error'
      );
    }

    try {
      await loadingIndicator.create();
      const r = await clientUsersService.create(clientId, user);
      refreshClientUsers();
      history.replace(`/client/users/${clientId}/${r.id}/`, {});
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const updateUser = async <K extends keyof ClientUser>(key: K, val: string | number | boolean) => {
    if (user?.id !== id || deepEqual(user[key], val)) {
      return;
    }

    try {
      const res = await clientUsersService.update(clientId, user.id, {
        [key]: val
      });

      setUser(res);

      appNotification.toast('User settings updated.', 'Success');
    } finally {
    }
  };

  const assignToClient = async (uid: number) => {
    try {
      await loadingIndicator.create();
      await clientUsersService.add(clientId, { id: uid });
      refreshClientUsers();
      history.replace(`/client/users/${clientId}/${uid}/`, {});
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const checkIntent = async (email: string) => {
    if (!email?.trim?.()) {
      appNotification.toast('Please enter in an email.', 'Error');
      return;
    }
    try {
      const res = await clientUsersService.checkIntent(
        clientId,
        email,
        'add_user'
      );

      const exists = res.data.count > 0;

      setShowAllFields(!exists);

      if (exists) {
        const uid = res.data.results[0].id;
        try {
          await clientUsersService.get(clientId, uid);
          setUserExists(exists);

          if (exists) {
            appNotification.alert({
              message: `${email} is already setup. Would you like to edit that user?`,
              header: 'User Exists',
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                  handler() {
                    setUser((u: ClientUser | undefined) => ({
                      ...u,
                      email: ''
                    } as ClientUser));
                  }
                },
                {
                  text: 'Yes',
                  handler() {
                    history.replace(`/client/users/${clientId}/${uid}/`, {});
                  }
                }
              ]
            });
          }
        } catch (e) {
          setUserExists(true);

          if (exists) {
            appNotification.alert({
              message: `${email} is already setup. Do you want to add them to your account?`,
              header: 'Add to Account?',
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                  handler() {
                    setUser((u: any) => ({ ...u, email: '' }));
                  }
                },
                {
                  text: 'Yes',
                  handler() {
                    assignToClient(uid);
                  }
                }
              ]
            });
          }
        }
      }
    } catch (e) { }
  };

  const updateField = <K extends keyof ClientUser>(key: K) => async (
    e: React.ChangeEvent<HTMLInputElement> | CustomEvent
  ) => {
    const val = 'detail' in e
      ? e.detail.checked ?? e.detail.value
      : e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value;

    if (isCreate) {
      if (key === 'email' && showAllFields) {
        checkIntent(val);
      }

      setUser((u: ClientUser | undefined) => ({
        ...u,
        [key]: val
      } as ClientUser));
    } else {
      updateUser(key, val);
    }
  };

  const emailField = (
    <TInputItem
      label="Email"
      value={user?.email}
      type="email"
      onEnterPressed={
        !showAllFields
          ? (e: React.KeyboardEvent<HTMLInputElement>) =>
            checkIntent((e.target as HTMLInputElement).value)
          : undefined
      }
      validation={(val: any) => !!val && !!val.match(/.*@.*\./g)}
      onBlur={updateField('email')}
    />
  );

  useRequireSettingsAdmin();
  return (
    <TPage
      loading={loading}
      headerTool={
        isCreate ? (
          <HapticButton
            color="secondary"
            slot="end"
            disabled={userExists || (showAllFields && !user?.cell_phone)}
            onClick={
              showAllFields ? createUser : (e: React.MouseEvent) => checkIntent(user?.email ?? '')
            }
          >
            {showAllFields ? 'Create' : 'Continue'}
          </HapticButton>
        ) : (
          undefined
        )
      }
    >
      {!loading && (
        <IonList lines="full" className="no-padding">
          {showAllFields ? (
            <>
              <IonItem>
                <IonLabel class="ion-multiline">
                  <TAvatar
                    src={photoPreview || user?.profile_image}
                    alt={`${user?.first_name} ${user?.last_name}`}
                    style={{ margin: 'auto', fontSize: 35, height: 100, width: 100 }}
                  />
                  <IonList style={{ textAlign: 'center' }}>
                    {!photoPreview ? (
                      <>
                        <IonButton fill="clear" color="primary" onClick={() => media.selectPhoto().then(res => handleMediaSelection(res, setPhotoPreview, setPhoto))}>
                          <IonIcon icon={camera} slot="start" />
                          {!!user?.profile_image ? 'Change' : 'Upload'}
                        </IonButton>
                        {user?.profile_image && (
                          <IonButton fill="clear" onClick={() => removeMedia('profile_image')}>
                            <IonIcon icon={closeCircle} slot="start" />
                            Remove
                          </IonButton>
                        )}
                      </>
                    ) : (
                      <>
                        <IonButton fill="clear" color="primary" onClick={() => saveMedia('profile_image')}>
                          <IonIcon icon={save} slot="start" />
                          Save
                        </IonButton>
                        <IonButton fill="clear" color="primary" onClick={() => { setPhoto(undefined); setPhotoPreview(undefined); }}>
                          <IonIcon icon={closeCircle} slot="start" />
                          Cancel
                        </IonButton>
                      </>
                    )}
                  </IonList>
                </IonLabel>
              </IonItem>
              <TItemGroup title="Salesperson Intro Video">
                <IonItem>
                  <IonLabel class="ion-multiline">
                    {introVideo ? (
                      <video width="100%" controls>
                        <source src={introVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p>No video uploaded</p>
                    )}
                    <IonList style={{ textAlign: 'center' }}>
                      <IonButton fill="clear" color="primary" onClick={() => media.selectVideo().then(res => handleMediaSelection(res, setIntroVideoPreview, setIntroVideo))}>
                        <IonIcon icon={camera} slot="start" />
                        {!!user?.intro_video ? 'Change Video' : 'Upload Video'}
                      </IonButton>
                      {user?.intro_video && (
                        <IonButton fill="clear" onClick={() => removeMedia('intro_video')}>
                          <IonIcon icon={closeCircle} slot="start" />
                          Remove Video
                        </IonButton>
                      )}
                      {!!introVideoPreview && (
                        <IonButton fill="clear" color="primary" onClick={() => saveMedia('intro_video')}>
                          <IonIcon icon={save} slot="start" />
                          Save Video
                        </IonButton>
                      )}
                    </IonList>
                  </IonLabel>
                </IonItem>
              </TItemGroup>
              <TItemGroup title="Cover Photo">
                <IonItem>
                  <IonLabel class="ion-multiline">
                    {(coverPhotoPreview || coverPhoto) ? (
                      <img src={coverPhotoPreview || coverPhoto} alt="Cover" style={{ width: '100%' }} />
                    ) : (
                      <p>No cover photo uploaded</p>
                    )}
                    <IonList style={{ textAlign: 'center' }}>
                      <IonButton fill="clear" color="primary" onClick={() => media.selectPhoto().then(res => handleMediaSelection(res, setCoverPhotoPreview, setCoverPhoto))}>
                        <IonIcon icon={camera} slot="start" />
                        {!!coverPhoto ? 'Change Cover Photo' : 'Upload Cover Photo'}
                      </IonButton>
                      {!!coverPhoto && (
                        <IonButton fill="clear" onClick={() => removeMedia('cover_photo')}>
                          <IonIcon icon={closeCircle} slot="start" />
                          Remove Cover Photo
                        </IonButton>
                      )}
                      {!!coverPhotoPreview && (
                        <IonButton fill="clear" color="primary" onClick={() => saveMedia('cover_photo')}>
                          <IonIcon icon={save} slot="start" />
                          Save Cover Photo
                        </IonButton>
                      )}
                    </IonList>
                  </IonLabel>
                </IonItem>
              </TItemGroup>
              <TItemGroup title="User Details">
                {emailField}
                <TInputItem
                  label="First Name"
                  value={user?.first_name}
                  onBlur={updateField('first_name')}
                />
                <TInputItem
                  label="Last Name"
                  value={user?.last_name}
                  onBlur={updateField('last_name')}
                />
                <TInputItem
                  label="Phonetic First Name"
                  value={user?.phonetic_first_name_spelling}
                  onBlur={updateField('phonetic_first_name_spelling')}
                />
                <TInputItem
                  label="Phonetic Last Name"
                  value={user?.phonetic_last_name_spelling}
                  onBlur={updateField('phonetic_last_name_spelling')}
                />
                {loggedInUser?.is_staff && (
                  <TSelectItem
                    label="Job Title"
                    multiple
                    placeholder="None"
                    value={user?.job_titles}
                    optionTextKey="name"
                    optionValueKey="id"
                    onChange={updateField('job_titles')}
                    options={jobTitles}
                  />
                )}
                <TInputItem
                  label="Cell # (required)"
                  onFocus={() => setMask('+1 (999) 999-9999')}
                  mask={mask}
                  type="tel"
                  validation={val => !!val && val.length === 12}
                  valueModifier={/[\s_()-]+/g}
                  value={user?.cell_phone}
                  onBlur={updateField('cell_phone')}
                />
                <TInputItem
                  label="Click-to-Call #"
                  onFocus={() => setMask('+1 (999) 999-9999')}
                  mask={mask}
                  type="tel"
                  validation={val => val === '' || (!!val && val.length === 12)}
                  valueModifier={/[\s_()-]+/g}
                  value={user?.click_to_call_phone}
                  onBlur={updateField('click_to_call_phone')}
                />
                <TInputItem
                  label="Click-to-Call Ext."
                  value={user?.click_to_call_extension}
                  onBlur={updateField('click_to_call_extension')}
                />
                <TInputItem
                  label="DMS ID"
                  onBlur={updateField('dms_id')}
                  value={user?.dms_id}
                />
                <TSelectItem
                  value={user?.direct_connect_preference}
                  options={[
                    { value: 'cell_phone', text: 'Cell Phone #' },
                    { value: 'click_to_call_number', text: 'Click-to-call #' }
                  ]}
                  onChange={updateField('direct_connect_preference')}
                  label="Inbound Calls"
                />
                <TToggleItem
                  onIonChange={updateField('press_to_connect')}
                  label="Press 1 to Connect"
                  checked={!!user?.press_to_connect}
                  wrapLabel
                />
                <TSelectItem
                  value={user?.mobile_theme}
                  options={[
                    { value: 'system', text: 'System' },
                    { value: 'dark', text: 'Dark' },
                    { value: 'light', text: 'Light' }
                  ]}
                  onChange={updateField('mobile_theme')}
                  label="Theme"
                />
                <TToggleItem
                  onIonChange={updateField('ai_grammar_correction')}
                  checked={!!user?.ai_grammar_correction}
                  label="AI Grammar Correction"
                  wrapLabel
                />
              </TItemGroup>
              <TItemGroup title="Social Info">
                <TInputItem
                  label="Bio"
                  value={user?.bio}
                  onBlur={updateField('bio')}
                />
                <TInputItem
                  label="Instagram"
                  value={user?.instagram_link}
                  onBlur={updateField('instagram_link')}
                />
                <TInputItem
                  label="Facebook"
                  value={user?.facebook_link}
                  onBlur={updateField('facebook_link')}
                />
                <TInputItem
                  label="Twitter"
                  value={user?.twitter_link}
                  onBlur={updateField('twitter_link')}
                />
                <TInputItem
                  label="LinkedIn"
                  value={user?.linkedin_link}
                  onBlur={updateField('linkedin_link')}
                />
              </TItemGroup>
              <TItemGroup title="Permissions">
                {clientService.isRoundRobin(client) && (
                  <TToggleItem
                    label="Round Robin Enabled"
                    checked={!!user?.round_robin_enabled}
                    onIonChange={updateField('round_robin_enabled')}
                  />
                )}
                <TToggleItem
                  label="Administrator"
                  checked={!!user?.is_client_admin}
                  onIonChange={updateField('is_client_admin')}
                />
                <TToggleItem
                  label="Can Edit Account Settings"
                  checked={!!user?.is_settings_admin}
                  onIonChange={updateField('is_settings_admin')}
                />
                <TToggleItem
                  label="Can View Credit Apps"
                  checked={!!user?.can_view_credit_apps}
                  onIonChange={updateField('can_view_credit_apps')}
                />
              </TItemGroup>

              <TItemGroup title="Notifications">
                <TSelectItem
                  label="Groups"
                  multiple
                  value={user?.notification_groups}
                  selectedText={`${user?.notification_groups?.length ??
                    0} ${util.getPluralizedString(
                      'Group',
                      user?.notification_groups?.length ?? 0
                    )}`}
                  onChange={updateField('notification_groups')}
                  options={groups?.map?.((grp: any) => ({
                    value: grp.id,
                    text: `@${grp.name}`
                  }))}
                />
                {user?.notification_groups && user.notification_groups.length > 0 && (
                  <IonItem className="groups-chips">
                    <IonGrid>
                      <IonRow>
                        {user.notification_groups?.map?.((id: any) => (
                          <IonCol
                            sizeLg="6"
                            sizeMd="6"
                            sizeXs="12"
                            sizeXl="4"
                            sizeSm="12"
                            key={id}
                          >
                            <IonChip>
                              <IonIcon color="primary" icon={people} />
                              <IonLabel>
                                {`@${groups.find((s: any) => s.id === id)?.name
                                  }`}
                              </IonLabel>
                              <IonIcon
                                color="primary"
                                icon={closeCircle}
                                onClick={(e: React.MouseEvent) => {
                                  e.stopPropagation();
                                  updateField('notification_groups')(
                                    new CustomEvent('custom', {
                                      detail: {
                                        value: user?.notification_groups?.filter((i: any) => i !== id)
                                      }
                                    })
                                  );
                                }}
                              />
                            </IonChip>
                          </IonCol>
                        ))}
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                )}
                {notifications.map(it => (
                  <TSelectItem
                    key={it.value}
                    value={user?.[it.value as keyof ClientUser]}
                    onChange={updateField(it.value as keyof ClientUser)}
                    options={[
                      { value: 'none', text: 'None' },
                      { value: 'email', text: 'Email' },
                      { value: 'sms', text: 'Push' },
                      { value: 'both', text: 'Both' }
                    ]}
                    label={it.label}
                  />
                ))}
              </TItemGroup>
              {/* <TItemGroup title="Schedule">

              </TItemGroup> */}
            </>
          ) : (
            <TItemGroup title="User Details">{emailField}</TItemGroup>
          )}
        </IonList>
      )}
    </TPage>
  );
};

export default EditClientUserPage;
