import React from 'react';
import { TModal } from './';
import ButtonGrid from '../ButtonGrid';
import { IonButton, IonList } from '@ionic/react';
import { haptics } from '../../core';
import { modalController } from '@ionic/core';
import TDateTimeItem from '../TDateTimeItem';

interface TradesFilterModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onApply: (since: string, until: string) => void;
  resetFilters: () => void;
  since: string | undefined;
  setSince: (since: string | undefined) => void;
  until: string | undefined;
  setUntil: (until: string | undefined) => void;
};

const TradesFilterModal: React.FC<TradesFilterModalProps> = ({
  isOpen,
  onDidDismiss,
  onApply,
  resetFilters,
  since,
  setSince,
  until,
  setUntil
}) => {
  return (
    <TModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      title="Filter Trades"
      footer={
        <ButtonGrid>
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => {
              haptics.lightImpact();
              modalController.dismiss();
              resetFilters();
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => {
              haptics.lightImpact();
              modalController.dismiss();
              onApply(since || '', until || '');
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList>
        <TDateTimeItem
          label="Last Updated"
          range
          toValue={until}
          fromValue={since}
          onFromIonChange={(e) => setSince(e.detail.value)}
          onToIonChange={(e) => setUntil(e.detail.value)}
        />
      </IonList>
    </TModal>
  )
}

export default TradesFilterModal;