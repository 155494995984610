import { http } from '../core';

class LeadVehiclesInterestService {
  async list(clientId: number | string, leadId: number | string) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/leads/${leadId}/vehicles-interest/`,
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(clientId: number | string, leadId: number | string, vehicleData: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/leads/${leadId}/vehicles-interest/`,
        data: vehicleData,
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
      throw e;
    }
  }
  async delete(clientId: number | string, leadId: number | string, vehicleId: number | string) {
    try {
      await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/leads/${leadId}/vehicles-interest/${vehicleId}/`,
      });
      return true;
    } catch (e) {
      http.onHttpError(e);
      throw e;
    }
  }
}

const leadVehiclesInterestService = new LeadVehiclesInterestService();
export default leadVehiclesInterestService;
