import { Plugins } from '@capacitor/core';
import { NetworkStatus } from '@capacitor/network';

import native from './native';
const { Network } = Plugins;

class NetworkUtil {
  private isOnline = true;
  networkType: string | null = 'unknown';
  hasNetworkConnection() {
    return this.isOnline;
  }

  private dispatch() {
    window.dispatchEvent(
      new CustomEvent('network:changed', { detail: this.isOnline })
    );
  }

  async check() {
    try {
      if (native.isNative) {
        const { connected } = await this.checkNative();
        return connected;
      }
      return navigator.onLine;
    } catch (error) {
      console.error("Error checking network status:", error);
      return false;
    }
  }

  private async checkNative() {
    return await Network.getStatus();
  }

  initBrowser() {
    window.addEventListener('online', this.onBrowserStatusChange.bind(this));
    window.addEventListener('offline', this.onBrowserStatusChange.bind(this));
    this.onBrowserStatusChange();
  }

  onBrowserStatusChange() {
    this.isOnline = navigator.onLine;
    this.dispatch();
  }

  onNativeStatusChange(e: NetworkStatus) {
    this.isOnline = e.connected;
    this.networkType = e.connectionType;
    this.dispatch();
  }

  async initNative() {
    Network.addListener(
      'networkStatusChange',
      this.onNativeStatusChange.bind(this)
    );

    const res = await this.checkNative();
    this.onNativeStatusChange(res);
  }

  async monitor() {
    if (native.isNative) {
      await this.initNative();
    } else {
      this.initBrowser();
    }

    return this;
  }
}

const networkUtil = new NetworkUtil();
export default networkUtil;
