import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';
import {
  IonCol,
  IonRow,
  IonGrid
} from '@ionic/react';
import { leadsService } from '../services';
import { AppContext } from '../context/AppContext';
import { MetricCard } from '../components';
import { ReassignLeadsModal } from '../components/modals';
import { appNotification } from '../core';

const UnassignedLeadsCountCard: React.FC = () => {
  const { state } = useContext(AppContext);
  const [data, setData] = useState<number>(0);
  const [loading, setLoading] = useState<any>(true);
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const loadData = useCallback(
    async () => {
      const count = await leadsService.getUnassignedCount(state.selectedClientId);
      setData(count);
      setLoading(false);
      return count;
    },
    [state.selectedClientId]
  );

  const isAdmin = useMemo(() => {
    const { is_admin, is_client_admin, is_settings_admin } = state.user;
    return is_admin || is_client_admin || is_settings_admin;
  }, [state.user]);

  const handleRoundRobin = useCallback(() => {
    if (data === 0) {
      appNotification.alert({
        message: 'There are no unassigned leads.',
        header: 'No Leads',
        buttons: [{ text: 'OK', role: 'cancel' }]
      })
    } else {
      setReassignModalOpen(true);
    }
  }, [data]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <IonGrid className="reports">
        {!loading && (
          <IonRow>
            <IonCol sizeSm="12" sizeXs="12" sizeMd="12" sizeLg="12" sizeXl="12">
              <MetricCard
                title="Unassigned"
                value={data}
                uom="Lead"
                onClick={isAdmin ? handleRoundRobin : undefined}
                buttonLabel="Round Robin"
              />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
      <ReassignLeadsModal
        user={null}
        leadCount={data}
        onDidDismiss={() => setReassignModalOpen(false)}
        allowNobodyOption={false}
        open={reassignModalOpen}
        onConfirm={() => {
          loadData();
          setReassignModalOpen(false);
        }}
      />
    </>
  );
};

export default UnassignedLeadsCountCard;
