import axios, { AxiosRequestConfig } from 'axios';
import authorization from './authorization';
import appConfig from '../config.json';
import util from './util';
import network from './network';
import { dispatch } from '../context/AppContext';
import { appNotification } from '.';
import environment from './environment';

export interface THttpRequest extends AxiosRequestConfig {
  type?: string;
  exclusive?: boolean;
  retries?: number;
  maxRetries?: number;
  resolve?: any;
  reject?: any;
  useStaging?: boolean;
}

class HTTP {
  private baseUrl: string;
  private cancelTokens: any = {};
  private queue: Array<THttpRequest> = [];
  private toastDisplayed: boolean = false;

  constructor() {
    const envUrlType = environment.apiEnvironment;
    const APIs = appConfig.tecobi.api;

    switch (envUrlType) {
      case 'development':
        this.baseUrl = APIs.development.baseUrl;
        break;
      case 'staging':
        this.baseUrl = APIs.staging.baseUrl;
        break;
      default:
        this.baseUrl = APIs.production.baseUrl;
    }
  }

  get FORM_HEADER() {
    return {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
  }

  processQueue() {
    const q = this.queue;
    q.forEach((it: THttpRequest) =>
      util.delay(() => this.tryRequest(it), (it.retries || 0) * 2000)
    );
    this.queue.length = 0;
  }

  private onNetworkError(req: THttpRequest, error: Error) {
    if (req.exclusive && req.type) {
      this.queue = this.queue.filter(it => it.type !== req.type);
    }

    this.queue.push(req);
  }

  private async tryRequest(options: THttpRequest) {
    if (!network.hasNetworkConnection()) {
      return this.onNetworkError(options, new Error('no network'));
    }


    const req: THttpRequest = Object.assign(
      {
        baseURL: this.baseUrl,
        timeout: options.timeout === undefined ? 30000 : Math.max(options.timeout, 1000)
      },
      options
    );

    if (req.baseURL?.match(/tecobi\.com/i)) {
      req.headers = req.headers || {};
      req.headers['X-TECOBI-VERSION'] = environment.clientVersion;
    }

    if (req.exclusive && req.type) {
      const existing = this.cancelTokens[req.type];
      if (existing) {
        existing.cancel();
      }

      const source = this.createCancelToken();
      this.cancelTokens[req.type] = source;
      req.cancelToken = source.token;
    }

    if (req.onUploadProgress) {
      const og = req.onUploadProgress;
      req.onUploadProgress = e => {
        const totalLength = e.lengthComputable
          ? e.total
          : e.target.getResponseHeader('content-length') ||
            e.target.getResponseHeader('x-decompressed-content-length');
        og(e.loaded / totalLength);
      };
    }

    try {
      const res = await axios(req);
      options.resolve(res);
    } catch (e : any) {
      if (axios.isCancel(e)) {
        e.cancelled = true;
        return options.reject(e);
      }

      let res = e.response || {};
      if (res.status === 401) {
        dispatch({ type: 'logout' });
        authorization.clear();
        window.location.reload();
        return;
      }

      if (res.status === 403) {
        const errMsg = res?.data?.detail ?? "You don't have access to this.";
        return appNotification.toast(
          errMsg,
          'Access Denied'
        );
      }

      if (e.message === 'Network Error' || e.code === 'ECONNABORTED') {
        req.retries = req.retries || 0;
        req.retries++;
        req.maxRetries = req.maxRetries || 5;
        if (!req.maxRetries || req.retries > req.maxRetries) {
          return this.onNetworkError(req, e);
        }
      }

      options.reject(e);
    }
  }

  async request(options: THttpRequest): Promise<any> {
    return new Promise((resolve, reject) => {
      options.resolve = resolve;
      options.reject = reject;
      this.tryRequest(options);
    });
  }

  // In http.ts
  async authorizedRequest(options: THttpRequest) {
    let token = await authorization.getToken();

    if (token) {
      // If token we have the old-style non-jwt token without a dot log out
      if (!token.includes(".")) {
        dispatch({ type: 'logout' });
        authorization.clear();
        window.location.reload();
        throw new Error("You have been logged out for security.");
      }
      // Assuming that `isTokenExpired` function is available
      if (authorization.isTokenExpired(token)) {
        // Assuming `refreshToken` function is available to refresh token.
        token = await authorization.refreshToken();

        // If token refresh failed (null, undefined, etc.), then handle it (logout, for example).
        if (!token) {
          dispatch({ type: 'logout' });
          authorization.clear();
          window.location.reload();
          throw new Error("Token refresh failed.");
        }

        // No need to set token here as it's already done in the refreshToken function
      }

      options.headers = options.headers || {};
      options.headers.Authorization = `Bearer ${token}`;
    }

    return await this.request(options);
  }


  async requestDataURI(options: THttpRequest) {
    const res = await this.request(
      Object.assign(options, {
        responseType: 'arraybuffer'
      })
    );

    const contentType = res.headers['content-type'];

    res.data = {
      uri: `data:${contentType};base64,${util.arraybufferToBase64(res.data)}`,
      contentType
    };

    return res;
  }

  getErrorMessage(e: any) {
    if (axios.isCancel(e)) {
      return 'cancelled';
    }

    let res = e.response || {};
    if (res.status === 400) {
      return Object.values(e.response.data)[0] as string;
    } else if (e.message) {
      return e.message;
    }

    return 'An unknown error has occurred. Please contact support.';
  }

  async uriToFile(url: string) {
    const { data, headers } = await this.request({
      method: 'GET',
      baseURL: '',
      url,
      responseType: 'blob'
    });

    const filename = url.substring(url.lastIndexOf('/') + 1);

    return new File([data], filename, {
      lastModified: Date.now(),
      type: headers['content-type']
    });
  }

  onHttpError(e: any, options: any = {}) {
    if (!axios.isCancel(e)) {
      console.error(e);
      const msg = this.getErrorMessage(e);
      if (!options.noToast && !this.toastDisplayed) {
        this.toastDisplayed = true;
        appNotification.toast(msg, 'Error', {
          onClose: () => {
            this.toastDisplayed = false;
          }
        });
      }
    }
  }

  createCancelToken() {
    return axios.CancelToken.source();
  }

  async loadNext(url: string | undefined) {
    if (!url) {
      throw new Error('URL is required');
    }
    try {
      const res = await this.authorizedRequest({
        method: 'GET',
        url
      });

      return res.data;
    } catch (e) {
      this.onHttpError(e);
      return { results: [], count: 0 };
    }
  }
}

const hTTP = new HTTP();
export default hTTP;
