import util from "./util";
import native from "./native";
import config from "../config.json";
import actionSheet from "./action-sheet";
import { MediaType } from "@ionic-native/camera";

export interface MediaResult {
  file: File;
  url: string;
}

class Media {
  private async promptForType(mediaType: MediaType): Promise<MediaResult> {
    return new Promise(async (resolve, reject) => {
      actionSheet.show([
        ...(mediaType === MediaType.PICTURE
          ? [
              {
                text: "Take Photo",
                handler: () => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*";
                  input.capture = "environment"; // Opens the camera for photos

                  input.onchange = (event: any) => {
                    const file = event.target.files[0];
                    if (file) {
                      const photoURL = URL.createObjectURL(file);
                      resolve({ file, url: photoURL });
                    }
                  };

                  input.click();
                  return true;
                },
              },
              {
                text: "Choose from Gallery",
                handler: () => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*"; // Opens file picker for images
                  input.onchange = (event: any) => {
                    const file = event.target.files[0];
                    if (file) {
                      const mediaURL = URL.createObjectURL(file);
                      resolve({ file, url: mediaURL });
                    }
                  };

                  input.click();
                  return true;
                },
              },
            ]
          : mediaType === MediaType.VIDEO
            ? [
                {
                  text: "Record Video",
                  handler: () => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = "video/*";
                    input.capture = "environment"; // Opens the video camera

                    input.onchange = (event: any) => {
                      const file = event.target.files[0];
                      if (file) {
                        const videoURL = URL.createObjectURL(file);
                        resolve({ file, url: videoURL });
                      }
                    };

                    input.click();
                    return true;
                  },
                },
                {
                  text: "Choose from Gallery",
                  handler: () => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = "video/*"; // Opens file picker for videos

                    input.onchange = (event: any) => {
                      const file = event.target.files[0];
                      if (file) {
                        const videoURL = URL.createObjectURL(file);
                        resolve({ file, url: videoURL });
                      }
                    };

                    input.click();
                    return true;
                  },
                },
              ]
            : [
                {
                  text: "Choose a File",
                  handler: () => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = config.media.emailAttachmentTypes;

                    input.onchange = (event: any) => {
                      const file = event.target.files[0];
                      if (file) {
                        const fileURL = URL.createObjectURL(file);
                        resolve({ file, url: fileURL });
                      }
                    };

                    input.click();
                    return true;
                  },
                },
              ]),
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            reject();
            return true;
          },
        },
      ]);
    });
  }

  async selectNativeMedia(type: MediaType): Promise<MediaResult> {
    if (native.isAndroid) {
      return await this.promptForType(type); // Uses actionSheet for photos and videos
    }
    // Fallback for iOS and Web (unchanged)
    return new Promise(async (resolve) => {
      util.chooseFile(resolve, config.media.imageTypes);
    });
  }

  async selectPhoto(): Promise<MediaResult> {
    if (native.isAndroid) {
      return await this.promptForType(MediaType.PICTURE); // Android fix
    } else {
      return new Promise(async (resolve) => {
        util.chooseFile(resolve, config.media.imageTypes);
      });
    }
  }

  async selectPhotos(): Promise<FileList> {
    return new Promise(async (resolve) => {
      util.chooseFiles(resolve, config.media.imageTypes);
    });
  }

  async selectEmailAttachmentFile(): Promise<MediaResult> {
    if (native.isAndroid) {
      return await this.promptForType(MediaType.ALLMEDIA); // Android fix
    } else {
      return new Promise(async (resolve) => {
        util.chooseFile(resolve, config.media.emailAttachmentTypes);
      });
    }
  }

  async selectVideo(): Promise<MediaResult> {
    if (native.isAndroid) {
      return await this.promptForType(MediaType.VIDEO); // Android fix
    } else {
      return new Promise(async (resolve) => {
        util.chooseFile(resolve, config.media.videoTypes);
      });
    }
  }
}

const media = new Media();
export default media;
