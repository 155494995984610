import React, { useContext, useState, useMemo } from 'react';
import '../../styles/components/ReportFilters.scss';
import TModal from './TModal';
import { IonList, IonButton, IonBadge } from '@ionic/react';
import TSelectItem from '../TSelectItem';
import ButtonGrid from '../ButtonGrid';
import { AppContext } from '../../context/AppContext';
import { leadsService } from '../../services';
import { loadingIndicator, http, util } from '../../core';
import TItem from '../TItem';

export interface ReassignLeadsModalProps {
  user: any;
  leadCount: number;
  onConfirm?: () => any;
  onDidDismiss: () => any;
  allowNobodyOption?: boolean;
  open: boolean;
}

const ReassignLeadsModal: React.FC<ReassignLeadsModalProps> = ({
  user,
  onConfirm,
  leadCount,
  onDidDismiss,
  allowNobodyOption = true,
  open,
}) => {
  const { state } = useContext(AppContext);
  const { clientUsers, selectedClientId } = state;
  const [selectedUsers, setSelectedUsers] = useState<number[]>(allowNobodyOption ? [-1] : []);

  const handleSelectionChange = (e: any) => {
    let { value } = e.detail;

    if (!value.includes(-1)) {
      setSelectedUsers(value);
    } else if (selectedUsers.includes(-1) && value.length > 1) {
      setSelectedUsers(value.filter((id: number) => id !== -1));
    } else {
      setSelectedUsers([-1]);
    }
  };

  const reassign = async () => {
    try {
      await loadingIndicator.create();
      const id = user?.id ?? null;

      if (selectedUsers.length === 0 && allowNobodyOption) {
        await leadsService.reassignLeads(selectedClientId, id, null);
      } else {
        await leadsService.reassignLeads(selectedClientId, id, undefined, selectedUsers);
      }

      onConfirm?.();
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const label = useMemo(() => {
    if (user) {
      return `Leads Assigned to ${user.full_name}`;
    } else {
      return `Unassigned Leads`;
    }
  }, [user]);

  return (
    <TModal
      className="reassign-leads-modal"
      isOpen={open}
      title="Reassign Leads"
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <IonButton color="secondary" expand="block" onClick={reassign}>
            Reassign
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList>
        <TItem label={label} wrapLabel>
          <IonBadge color="secondary" mode="ios" slot="end">
            {util.formatNumber(leadCount)}
          </IonBadge>
        </TItem>
        <TSelectItem
          multiple
          label="Reassign To"
          value={selectedUsers}
          onChange={handleSelectionChange}
          options={[
            ...(allowNobodyOption ? [{ value: -1, text: 'Nobody' }] : []),
            ...clientUsers
              .filter((it: any) => !user || it.id !== user.id)
              .filter((it: any) => !it.is_staff)
              .map((it: any) => ({ value: it.id, text: it.full_name }))
          ]}
        />
      </IonList>
    </TModal>
  );
};

export default ReassignLeadsModal;
