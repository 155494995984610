import React, { useContext, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ChatMessageBox from './ChatMessageBox';
import ChatToolButton from './ChatToolButton';
import { util, loadingIndicator, appNotification } from '../core';
import { helpBuoy, pause, apps, arrowUp, mail } from 'ionicons/icons';
import { IonProgressBar, IonItem, IonRouterLink, IonButton, IonIcon } from '@ionic/react';
import { leadsService, inboundLeadsService, userService } from '../services';
import { LeadContext, scrollToBottom } from '../context/LeadContext';
import ChatFilePreview from './ChatFilePreview';
import ChatFilePreviewGrid from './ChatFilePreviewGrid';
import ChatTools from './ChatTools';
import css from 'classnames';
import '../styles/components/ChatFooter.scss';
import {
  ChatMessageBoxContext,
  getMessageText,
  clearMessage,
  setMessageFocus,
  getMessageInput,
} from '../context/ChatMessageBoxContext';
import {
  useKeyboardShortcuts,
  useRouter,
  usePortal,
  usePopper,
  useResetOnLeave,
} from '../hooks';
import moment from 'moment';
import { AppContext } from '../context/AppContext';

const ChatFooter: React.FC = () => {
  const router = useRouter();
  const nextLeadButton = useRef<any>();
  const chatFooter = useRef<any>();
  const { state: appState } = useContext(AppContext);
  const gettingNextLead = useRef<boolean>(false);
  const { user } = appState;
  const disallowPauseShorcut = user.is_text_ninja || user.is_phone_ninja;
  const portal = usePortal('waiting-lead-portal');
  useResetOnLeave();
  usePopper(chatFooter, nextLeadButton, {
    placement: 'top',
  });

  const {
    state,
    dispatch,
    createNewMessage,
    createNewInternalMessage,
    leadUpdated,
    nextLeadClientId,
    isInbound,
    isNeedsCall,
    getNextLeadLink,
  } = useContext(LeadContext);

  const isOptOut = !!(state && state.lead && state.lead.sms_opt_out)
    ? state?.lead?.sms_opt_out > 8
    : false;

  const allowEmail = !!state?.lead?.email_address;
  const forceEmail = allowEmail && !state?.lead?.phone_number;

  const {
    isEmptyMessage,
    appendMessage,
    state: cbState,
    dispatch: cbDispatch,
  } = useContext(ChatMessageBoxContext);

  const {
    selectedGif,
    selectedFile,
    selectedFiles,
    selectedFilePreview,
    selectedFilePreviews,
    internalOnly,
    isEmail,
    allNotificationGroups,
    showAllTools,
    shortcode,
  } = cbState;
  const { pause_date } = state.lead || {};

  useEffect(() => {
    if (isEmail) {
      appNotification.toast(
        `Email mode is now activated.  You are sending email to ${state?.lead?.email_address}`,
        'Email Mode',
        { color: 'beige', duration: 1500 }
      );
    }
  }, [isEmail, state?.lead?.email_address]);

  useEffect(() => {
    // Turn off internal mode when email mode on
    if (isEmail) {
      cbDispatch({
        type: 'set',
        value: {
          internalOnly: false,
        },
      });
    }
  }, [isEmail, cbDispatch]);

  useEffect(() => {
    // Turn off email when internal mode on
    if (internalOnly) {
      cbDispatch({
        type: 'set',
        value: {
          isEmail: false,
        },
      });
    }
  }, [internalOnly, cbDispatch]);

  useEffect(() => {
    if (forceEmail) {
      cbDispatch({
        type: 'set',
        value: {
          isEmail: true,
        },
      });
    }
  }, [forceEmail, cbDispatch]);


  const paused = !!pause_date;

  const pauseShortcut =
    (duration: number, interval: any, message?: string, disallow = false) =>
      async () => {
        // Don't let ninja's use pause shortcuts because they abuse it.
        if (!paused && !disallow) {
          try {
            await loadingIndicator.create();

            const date = moment()
              .tz(leadsService.getTimezone(state.lead))
              .add(duration, interval);

            const lead = state.lead;

            const res = await leadsService.update(
              {
                client: lead?.client,
                id: lead?.id,
                pause_date: date.toISOString(),
                pause_message: message,
              },
              { noToast: true }
            );

            leadUpdated(res);

            await createNewInternalMessage({
              message: `Pause Date ${date.format('MM/DD/YY hh:mma zz')}`,
            });

            if (message) {
              await createNewInternalMessage({
                message: `Pause Message: ${message}`,
              });
            }

            await getNextLead();
          } finally {
            loadingIndicator.dismiss();
          }
        }
      };

  const checkCustomShortcodeBehavior = (code: string | null | undefined) => {
    switch (code) {
      case ':no':
        pauseShortcut(
          1,
          'month',
          'Are you ready to look at vehicles yet?',
          false
        )();
        break;
    }
  };

  // const sendShortcode = (shortcode: string) => async () => {
  //   const reply = appState.shortcodes[shortcode];
  //   expandQuickReply(reply, state.lead);
  //   await sendMessage();
  //   checkCustomShortcodeBehavior(shortcode);
  // };

  const sendMessage = async (e?: any) => {
    const message = getMessageText();

    setMessageFocus();

    if (cbState.showMentionsPopup) {
      return cbDispatch({
        type: 'setMention',
      });
    }

    if (util.isNativeOrModifierEnter(e)) {
      return appendMessage('\n');
    }
    if (isOptOut && !(cbState.internalOnly || isEmail)) {
      appNotification.toast(
        'You cannot send messages to leads who have opted out.',
        'Opt-out',
        { color: 'danger' }
      );
      return;
    }
    if (isEmptyMessage()) {
      return;
    }

    if (state.sending === true) {
      return;
    }

    const lastUsedShortcode = shortcode;

    cbDispatch({
      type: 'set',
      value: {
        shortcode: null,
        isEmail: false,
        selectedGif: null,
        selectedFile: null,
        selectedFilePreview: null,
        selectedFiles: null,
        selectedFilePreviews: null,
      },
    });

    clearMessage();

    dispatch({
      type: 'set',
      value: {
        sending: true,
      },
    });

    const gif_url = selectedGif;
    const text_ninja = internalOnly;
    const is_email = isEmail;
    let media = selectedFile;
    let outbound_media = JSON.stringify(selectedFiles);
    const preview = selectedFilePreview;
    // Check if media is an image and its size exceeds 5MB and is not and email nor internal
    if (
      !is_email &&
      !text_ninja &&
      media &&
      media.type.startsWith('image/') &&
      media.size > 5 * 1024 * 1024
    ) {
      try {
        const resizedImageBlob = await util.resizeImage(media);
        media = new File([resizedImageBlob], media.name, { type: media.type });
      } catch (error) {
        console.error('Failed to resize the image:', error);
      }
    }

    if (!isEmail && state.lead?.carrier_type === 'landline') {
      appNotification.toast(
        'Message not sent, number is a landline.',
        'Error',
        { color: 'danger' }
      );
    }

    try {
      await createNewMessage(
        {
          message,
          gif_url,
          text_ninja,
          media,
          outbound_media,
          is_email,
          outbound_email_address: is_email
            ? state?.lead?.email_address
            : undefined,
          email_subject: is_email ? state?.lead?.client_name : undefined,
        },
        (pct: number) => dispatch({ type: 'set', value: { sendProgress: pct } })
      );

      util.delay(scrollToBottom, 100);

      if (!(isInbound || isNeedsCall)) {
        inboundLeadsService
          .getNextInboundLead(user, nextLeadClientId())
          .then((lead: any) => {
            if (lead) {
              dispatch({ type: 'set', value: { nextLead: lead } });
            }
          });
      } else if (inboundLeadsService.inboundCount > 0) {
        dispatch({ type: 'set', value: { nextLead: true } });
      }

      checkCustomShortcodeBehavior(lastUsedShortcode);
    } catch (e) {
      cbDispatch({
        type: 'set',
        value: {
          message,
          shortcode: lastUsedShortcode,
          selectedGif: gif_url,
          selectedFile: media,
          selectedFilePreview: preview,
        },
      });
      console.error(e);
      appNotification.toast(
        'Error sending message. Please try again.',
        'Error'
      );
    } finally {
      dispatch({
        type: 'set',
        value: {
          sendProgress: 0,
          sending: false,
        },
      });
    }

    window.dispatchEvent(
      new CustomEvent('chatboxmessage:change', { detail: { value: '' } })
    )

    const input = getMessageInput();
    if (input) {
      input.style.height = '45px';
    }
  };

  const unpause = async () => {
    try {
      await loadingIndicator.create();
      const { lead } = state;
      const updates = {
        id: lead?.id,
        client: lead?.client,
        pause_date: null,
        pause_message: null,
      };
      const updated = await leadsService.update(updates, {
        successMessage: 'Lead has been unpaused.',
      });
      dispatch({
        type: 'set',
        value: { lead: updated },
      });

      await createNewMessage({
        message: 'Pause date cleared',
        text_ninja: true,
      });

      util.delay(() => scrollToBottom?.(), 250);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const toggleInternalOnly = () => {
    cbDispatch({
      type: 'set',
      value: {
        internalOnly: !internalOnly,
      },
    });
    setMessageFocus();
  };

  const toggleIsEmail = () => {
    cbDispatch({
      type: 'set',
      value: {
        isEmail: forceEmail ? true : !isEmail,
      },
    });
    setMessageFocus();
  };

  const resetSelectedGif = () =>
    cbDispatch({
      type: 'set',
      value: { selectedGif: null },
    });

  const removeSelectedFile = () =>
    cbDispatch({
      type: 'set',
      value: {
        selectedFile: null,
        selectedFilePreview: null,
      },
    });

  const handleRemoveFileByIndex = (index: number) => {
    const updatedFiles = selectedFiles.filter((_: any, i: number) => i !== index);
    const updatedPreviews = selectedFilePreviews.filter((_: any, i: number) => i !== index);

    cbDispatch({
      type: 'set',
      value: {
        selectedFiles: updatedFiles,
        selectedFilePreviews: updatedPreviews,
      },
    });
  };

  const onPauseClicked = () =>
    paused
      ? unpause()
      : dispatch({ type: 'set', value: { showPauseModal: true } });

  const toggleAllTools = () =>
    cbDispatch({
      type: 'set',
      value: {
        showAllTools: !showAllTools,
      },
    });

  const getNextLead = async () => {
    if (gettingNextLead.current === true) {
      return;
    }

    try {
      const isInbound = util.locationIsInbound(router.location);
      const isNeedsCall = util.locationIsNeedsCall(router.location);
      const currentLeadId = state.lead?.id;
      if ((isInbound || isNeedsCall) && currentLeadId) {
        inboundLeadsService.removeServedLead(currentLeadId);
      }

      dispatch({ type: 'clear' });
      gettingNextLead.current = true;
      await loadingIndicator.create(`Getting next lead...`);

      const nextLead = await inboundLeadsService.getNextInboundLead(
        user,
        nextLeadClientId(),
        isNeedsCall
      );

      await loadingIndicator.dismiss();

      if (nextLead) {
        const clientId = nextLead.client_id;
        const leadId = nextLead.id;
        router.replace(
          leadsService.getLink(clientId, leadId, isInbound, isNeedsCall)
        );
      } else {
        appNotification.toast(
          'There are no more leads waiting.',
          'No Leads Waiting'
        );
        router.replace(isInbound || isNeedsCall ? '' : '/text-messages/', {});
      }
    } finally {
      gettingNextLead.current = false;
    }
  };

  const sendAndGetNextLead = (tag?: boolean) => async () => {
    await sendMessage(null);

    if (tag && state.lead) {
      let message =
        '@sales @bdc we need some help with this customer. Please pick up the customer.';
      if (state.lead?.assigned_to_info) {
        const mention = allNotificationGroups.find(
          (it) => it.name === state.lead?.assigned_to_info?.full_name
        );
        if (mention) {
          message = `@${mention.username} we need your assistance with one of your customers.`;
        }
      }
      await createNewInternalMessage({
        message,
      });
    } else {
      //Don't set next lead if they tagged the salesperson
      await getNextLead();
    }
  };

  const archiveAndGetNextLead = async () => {
    try {
      await loadingIndicator.create();
      const { lead } = state;
      const res = await leadsService.archive(lead?.client, lead?.id, {
        noToast: true,
      });
      leadUpdated(res);
      await getNextLead();
    } finally {
      loadingIndicator.dismiss();
    }
  };

  useKeyboardShortcuts({
    'Ctrl+1': toggleInternalOnly,
    'Ctrl+2': sendAndGetNextLead(false),
    'Ctrl+i': () => {
      if (userService.canAccessInboundQueue(user)) {
        dispatch({
          type: 'set',
          value: { showLeadNotesModal: !state.showLeadNotesModal },
        });
      }
    },
    // Ctrl+3 being used too much by mistake
    // 'Ctrl+3': sendAndGetNextLead(true),
    'Ctrl+4': pauseShortcut(1, 'hour', undefined, disallowPauseShorcut),
    'Ctrl+5': pauseShortcut(1, 'month', undefined, disallowPauseShorcut),
    'Ctrl+6': pauseShortcut(1, 'year', undefined, disallowPauseShorcut),
    'Ctrl+7': pauseShortcut(1, 'day', undefined, disallowPauseShorcut),
    'Ctrl+8': pauseShortcut(1, 'week', undefined, disallowPauseShorcut),
    'Ctrl+9': pauseShortcut(3, 'month', undefined, disallowPauseShorcut),
    'Ctrl+0': archiveAndGetNextLead,
    'Ctrl+Space': () =>
      cbDispatch({
        type: 'set',
        value: {
          showQuickReplies: !cbState.showQuickReplies,
        },
      }),
  });

  if (state.loading) {
    return null;
  }

  return (
    <>
      {state.nextLead &&
        ReactDOM.createPortal(
          <IonRouterLink
            className='waiting-lead'
            ref={nextLeadButton}
            routerLink={
              state.nextLead?.client_id
                ? getNextLeadLink(state.nextLead?.client_id, state.nextLead?.id)
                : undefined
            }
            onClick={!state.nextLead?.client_id ? getNextLead : undefined}
            routerDirection='none'
          >
            Go to Next Lead
          </IonRouterLink>,
          portal
        )}
      <ChatTools />
      {state.sending && (
        <div>
          <IonProgressBar color='success' value={state.sendProgress} />
        </div>
      )}
      {selectedGif && (
        <ChatFilePreview
          onRemoveClick={resetSelectedGif}
          contentType='image/gif'
          alt='Selected GIF'
          src={selectedGif}
        />
      )}
      {selectedFile && (
        <ChatFilePreview
          onRemoveClick={removeSelectedFile}
          contentType={selectedFile.type}
          name={selectedFile.name}
          alt='Selected Media'
          src={selectedFilePreview}
        />
      )}
      {selectedFiles && selectedFiles.length > 0 && (
        <ChatFilePreviewGrid
          files={selectedFiles.map((file: any, index: number) => ({
            url: file.url,
            contentType: file.content_type,
            preview: selectedFilePreviews[index],
          }))}
          onRemoveClick={(index) => handleRemoveFileByIndex(index)}
        />
      )}
      <IonItem
        lines='none'
        ref={chatFooter}
        className={css('chat-footer', {
          internal: cbState.internalOnly,
          optout: !isEmail && isOptOut && !cbState.internalOnly,
        })}
      >
        <ChatToolButton
          icon={pause}
          color={paused ? 'warning' : 'primary'}
          title={paused ? 'Paused' : 'Pause'}
          action={onPauseClicked}
        />
        <ChatToolButton
          className='pointer internal-toggle-button'
          icon={helpBuoy}
          color={cbState.internalOnly ? 'warning' : ''}
          title='Toggle Internal Only'
          action={toggleInternalOnly}
        />
        {allowEmail && (
          <ChatToolButton
            className='pointer internal-toggle-button'
            icon={mail}
            color={cbState.isEmail ? 'beige' : ''}
            title='Toggle Is Email'
            action={toggleIsEmail}
          />
        )}
        <ChatToolButton
          icon={apps}
          color={cbState.showAllTools ? 'secondary' : 'primary'}
          title='Show All Tools'
          action={toggleAllTools}
        />
        <ChatMessageBox sendMessage={sendMessage} />
        <IonButton
          className={css('send-message-button', {
            beige: isEmail,
            visible:
              !isEmptyMessage() &&
              (!!isEmail || !isOptOut || cbState.internalOnly),
          })}
          title='Send Message'
          onClick={sendMessage}
          fill="clear"
        >
          <IonIcon icon={arrowUp} size="large" color="white" />
        </IonButton>
      </IonItem>
    </>
  );
};

export default ChatFooter;
