import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {
  TInfiniteScrollPage,
  TextMessageRow,
  TSearchBar,
  TFab,
  HapticButton,
} from '../components';
import { messagesService } from '../services';
import {
  funnel,
  notifications,
  arrowDown,
  settings,
  checkmark,
  add,
  closeCircle,
} from 'ionicons/icons';
import {
  IonIcon,
  IonItemDivider,
  IonItem,
  IonChip,
  IonLabel,
  IonBadge,
  IonGrid,
  IonRow,
  IonPopover,
  IonList,
  IonButton,
  IonCol,
  IonText
} from '@ionic/react';

import { AppContext } from '../context/AppContext';
import { LeadContext } from '../context/LeadContext';
import { haptics } from '../core';
import { LeadFiltersModal } from '../components/modals';
import { useLiveDBRef, useDebounce, useDataLoader } from '../hooks';
import _ from 'lodash';

const TextMessagesPage: React.FC = () => {
  const searchElement = useRef<any>();
  const { state, dispatch } = useContext(AppContext);
  const { leadSorting, selectedClientId } = state;
  const leadContext = useContext(LeadContext);
  const { dispatch: leadDispatch, resetFilters } = leadContext;
  const { filters } = leadContext.state;
  const filtersKeys = Object.keys(filters);
  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [inboundOnly, setInboundOnly] = useState(false);
  const sortAscending = leadSorting !== 'Newest First';
  const [searchIn, setSearchIn] = useState<string|undefined>(undefined);
  const [vin, setVin] = useState<string | null>(null);
  const [stockNumber, setStockNumber] = useState<string | null>(null);
  const [showPopover, setShowPopover] = useState({
    open: false,
    event: undefined,
  });
  const [searchMode, setSearchMode] = useState('standard');
  const getPlaceholderText = () => {
    switch (searchMode) {
      case 'vin':
        return 'Search by VIN';
      case 'stock':
        return 'Search by Stock #';
      case 'messages':
        return 'Search Text Messages'
      default:
        return 'Search by Name, Phone, or Email';
    }
  };

  const handleSearchModeChange = (mode: string) => {
    let cleaned = searchValue.replace(/-|[{()}]|\.|,|\s/g, '');

    switch (mode) {
      case 'vin':
        setVin(cleaned);
        setStockNumber(null);
        setSearchIn(undefined);
        break;
      case 'stock':
        setStockNumber(cleaned);
        setVin(null);
        setSearchIn(undefined);
        break;
      case 'messages':
        setSearchIn('messages');
        setVin(null);
        setStockNumber(null);
        break;
      default:
        setVin(null);
        setStockNumber(null);
        setSearchIn(undefined);
        break;
    }
    setSearchMode(mode);
    setShowPopover({ open: false, event: undefined });
  };

  const loadData = useCallback(async () => {
    return inboundOnly
      ? await messagesService.listWaiting({
          clientId: state.selectedClientId,
          search: vin || stockNumber ? '' : searchValue,
          vin,
          stockNumber,
          searchIn,
          sorting: leadSorting,
          exclusive: true,
        })
      : await messagesService.list({
          clientId: state.selectedClientId,
          search: vin || stockNumber ? '' : searchValue,
          vin,
          stockNumber,
          searchIn,
          filters,
          sorting: leadSorting,
          exclusive: true,
        });
  }, [
    state.selectedClientId,
    vin,
    stockNumber,
    searchIn,
    searchValue,
    leadSorting,
    filters,
    inboundOnly,
  ]);

  const {
    error,
    next,
    loading,
    data,
    count,
    refresher,
    loadNext,
    dispatch: dataDispatch,
    forceRefresh,
  } = useDataLoader(loadData, true);

  const onTextMessageChange = useDebounce(async (e: any) => {
    // Do not refresh if searchValue is set (user is searching)
    if (searchValue) {
      return;
    }
    if (selectedClientId && !sortAscending) {
      const opts = {
        clientId: selectedClientId,
      };

      const updateResults = (results: any) => {
        dataDispatch({
          type: 'dataFunction',
          value: (cur: any = []) =>
            _.sortBy(
              _.uniqBy([...results, ...cur], (it: any) => it.id),
              (it) => it.updated
            ),
        });
      };
      const inbound = await messagesService.listWaiting(
        Object.assign(opts, { pageSize: inboundOnly ? 100 : 1 })
      );

      const clientNeedsCallCount = await messagesService.loadNeedsCall(
        state.selectedClientId
      );

      dispatch({
        type: 'inboundUpdated',
        value: inbound,
      });

      dispatch({
        type: 'needsCallCountUpdated',
        value: clientNeedsCallCount?.clientNeedsCallCount,
      });

      if (inboundOnly) {
        updateResults(inbound.results);
        // After sending outbound text, hitting "back" button
        // when inboundOnly is true, clientInboundCount correctly decrements
        // while inbound.results do not update, so forceRefresh() fixes it.
        if (inbound.results.length < state.clientInboundCount) {
          // commenting out because its causing refreshing constantly during blast send
          //forceRefresh();
        }
      } else {
        const all = await messagesService.list(
          Object.assign(opts, { filters, search: searchElement.current?.value })
        );
        updateResults(all.results);
      }
    }
  }, 20000);
  useLiveDBRef(
    `/text-messages/conversation/${selectedClientId}/`,
    onTextMessageChange
  );

  useEffect(() => {
    let cleaned = searchValue.replace(/-|[{()}]|\.|,|\s/g, '');
    switch (searchMode) {
      case 'vin':
        setVin(cleaned);
        setStockNumber(null);
        break;
      case 'stock':
        setStockNumber(cleaned);
        setVin(null);
        break;
      default:
        setVin(null);
        setStockNumber(null);
        break;
    }
  }, [searchValue, searchMode]);

  return (
    <TInfiniteScrollPage
      loading={loading}
      toolbarLoadingIndicator={searching && loading}
      toolbar={
        <>
          <IonItemDivider>
            <TSearchBar
              ref={searchElement}
              onSearch={(search: string) => {
                // If a number is detected and no letters, strip out spaces, dashes, dots, parenthesis, commas
                if (/\d/.test(search) && !/[a-zA-Z]/.test(search)) {
                  search = search.replace(/-|[{()}]|\.|,|\s/g, '');
                }
                if (search === searchValue) {
                  forceRefresh();
                }
                setSearchValue(search);
                setSearching(true);
              }}
              placeholder={getPlaceholderText()}
            />
            <HapticButton
              icon={settings}
              size="default"
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ open: true, event: e });
              }}
            />
            <HapticButton
              icon={funnel}
              size="default"
              onClick={() => {
                leadContext.dispatch({
                  type: 'set',
                  value: { showLeadFilters: true },
                });
              }}
            />
          </IonItemDivider>
          {(inboundOnly || sortAscending || filtersKeys.length > 0) && (
            <div className='filters-row'>
              {inboundOnly && (
                <IonChip color='primary'>
                  <IonIcon icon={notifications} />
                  <IonLabel>Customers Waiting</IonLabel>
                  <IonBadge mode='ios'>{state.clientInboundCount}</IonBadge>
                  <IonIcon
                    icon={closeCircle}
                    onClick={() => {
                      setInboundOnly(false);
                      resetFilters();
                    }}
                  />
                </IonChip>
              )}
              {sortAscending && (
                <IonChip color='primary'>
                  <IonIcon icon={arrowDown} />
                  <IonLabel>Oldest Messages First</IonLabel>
                  <IonIcon
                    icon={closeCircle}
                    onClick={() =>
                      dispatch({
                        type: 'set',
                        value: { leadSorting: 'Newest First' },
                      })
                    }
                  />
                </IonChip>
              )}
              {!inboundOnly &&
                filtersKeys.map(
                  (k: string) =>
                    filters[k] !== '' && (
                      <IonChip color='primary' key={k}>
                        <IonIcon icon={leadContext.filterIcon(k)} />
                        <IonLabel>
                          {leadContext.filterDisplay(k, filters[k])}
                        </IonLabel>
                        <IonIcon
                          icon={closeCircle}
                          onClick={() => {
                            delete filters[k];
                            leadContext.dispatch({
                              type: 'set',
                              value: {
                                filters: { ...filters },
                              },
                            });
                          }}
                        />
                      </IonChip>
                    )
                )}
            </div>
          )}
          {(state.clientInboundCount > 0 || state.clientNeedsCallCount > 0) &&
            !inboundOnly &&
            !filters.needs_call && (
              <IonItem className='inbound'>
                <IonGrid>
                  <IonRow justify-content-start align-items-start>
                    {state.clientInboundCount > 0 && (
                      <IonCol>
                        <IonButton
                          expand='full'
                          color='secondary'
                          onClick={() => setInboundOnly(true)}
                        >
                          {state.clientInboundCount} needing text
                        </IonButton>
                      </IonCol>
                    )}
                    {state.clientNeedsCallCount > 0 && true && (
                      <IonCol>
                        <IonButton
                          expand='full'
                          color='orange'
                          onClick={() => {
                            setInboundOnly(false);
                            const changes = {
                              needs_call: 'Call Needed',
                            } as any;
                            leadDispatch({
                              type: 'set',
                              value: {
                                filters: { ...changes },
                              },
                            });
                          }}
                        >
                          {state.clientNeedsCallCount} needing call
                        </IonButton>
                      </IonCol>
                    )}
                  </IonRow>
                </IonGrid>
              </IonItem>
            )}
            <IonItemDivider>
              <IonText>
                <p>{`Showing ${data?.length ?? 0} of ${count}`}</p>
              </IonText>
            </IonItemDivider>
        </>
      }
      items={data}
      error={error}
      hasNext={!!next}
      fab={
        state.selectedClient.default_number ? (
          <TFab
            icon={add}
            onClick={() => {
              haptics.lightImpact();
              dispatch({ type: 'set', value: { showAddLeadModal: true } });
            }}
          />
        ) : undefined
      }
      onRefresh={refresher}
      zeroStateText='No Text Messages Found.'
      onLoadNextPage={loadNext}
      renderItem={(item: any) => <TextMessageRow key={`${item.id}-${item.client_info.id}`} item={item} />}
    >
      <LeadFiltersModal />
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
      >
        <IonList>
          <IonItem
            lines='full'
            button
            onClick={() => handleSearchModeChange('standard')}
          >
            {searchMode === 'standard' && (
              <IonIcon icon={checkmark} slot='start' />
            )}
            Standard Search
          </IonItem>
          <IonItem
            lines='full'
            button
            onClick={() => handleSearchModeChange('stock')}
          >
            {searchMode === 'stock' && (
              <IonIcon icon={checkmark} slot='start' />
            )}
            Stock Number Search
          </IonItem>
          <IonItem
            lines='full'
            button
            onClick={() => handleSearchModeChange('vin')}
          >
            {searchMode === 'vin' && <IonIcon icon={checkmark} slot='start' />}
            VIN Search
          </IonItem>
          <IonItem
            lines='full'
            button
            onClick={() => handleSearchModeChange('messages')}
          >
            {searchMode === 'messages' && <IonIcon icon={checkmark} slot='start' />}
            Message Search
          </IonItem>
        </IonList>
      </IonPopover>
    </TInfiniteScrollPage>
  );
};

export default TextMessagesPage;
