import React, { useContext, useState, useEffect } from 'react';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
import { leadsService } from '../services';
import TExpandableItemGroup from './TExpandableItemGroup';
import TDatePicker from './TDatePicker';
import TInputItem from './TInputItem';
import TItem from './TItem';
import TSelectItem from './TSelectItem';
import TAddressSearch from './TAddressSearch';
import moment from 'moment';

const LeadContactDetails: React.FC = () => {
  const { state, updateAndSaveLead, leadUpdated } = useContext(LeadContext);
  const { state: appState } = useContext(AppContext);
  const { jobTitles, user } = appState;
  const { lead, loading } = state;
  const [leadJobTitles, setLeadJobTitles] = useState<any>(lead?.job_titles);
  const [mask, setMask] = useState<string>('');

  useEffect(() => {
    setTimeout(() => {
      setMask('+1 (999) 999-9999')
    }, 100);
  },[])

  if (loading || !lead) {
    return null;
  }

  return (
    <TExpandableItemGroup title="Contact Details">
      <TInputItem
        labelPlacement="stacked"
        label="First Name"
        autocapitalize="on"
        onBlur={e => updateAndSaveLead('first_name', e)}
        value={lead.first_name}
      />
      <TInputItem
        labelPlacement="stacked"
        label="Last Name"
        autocapitalize="on"
        onBlur={e => updateAndSaveLead('last_name', e)}
        value={lead.last_name}
      />
      <TInputItem
        labelPlacement="stacked"
        label="Company"
        autocapitalize="on"
        onBlur={e => updateAndSaveLead('company_name', e)}
        value={lead.company_name}
      />
      {user?.is_staff && (
        <TSelectItem
          label="Job Title"
          labelPlacement="stacked"
          multiple
          placeholder="None"
          value={leadJobTitles}
          optionTextKey="name"
          optionValueKey="id"
          onChange={(e) => {
            setLeadJobTitles(e.detail.value);
            updateAndSaveLead('job_titles', e);
          }}
          options={jobTitles}
        />
      )}
      <TInputItem
        labelPlacement="stacked"
        label="Cell Phone"
        type="tel"
        onFocus={() => setMask('+1 (999) 999-9999')}
        mask={mask}
        validation={val => val === '' || (!!val && val.length === 12)}
        valueModifier={/[\s_()-]+/g}
        onBlur={e => updateAndSaveLead('phone_number', e)}
        value={lead.phone_number}
      />
      <TSelectItem
        label="Cell Carrier Type"
        labelPlacement="stacked"
        value={lead?.carrier_type}
        onChange={(e) => console.log('carrier_type', e.detail.value)}
        options={['mobile', 'landline', 'voip']}
      />
      <TInputItem
        labelPlacement="stacked"
        label="Home Phone"
        onFocus={() => setMask('+1 (999) 999-9999')}
        mask={mask}
        type="tel"
        validation={val => !!val && val.length === 12}
        valueModifier={/[\s_()-]+/g}
        onBlur={e => updateAndSaveLead('home_phone', e)}
        value={lead.home_phone}
      />
      <TInputItem
        labelPlacement="stacked"
        label="Work Phone"
        onFocus={() => setMask('+1 (999) 999-9999')}
        mask={mask}
        type="tel"
        validation={val => !!val && val.length === 12}
        valueModifier={/[\s_()-]+/g}
        onBlur={e => updateAndSaveLead('work_phone', e)}
        value={lead.work_phone}
      />
      <TInputItem
        labelPlacement="stacked"
        label="Email"
        type="email"
        validation={val => (!!val && !!val.match(/.*@.*\./g)) || val?.length === 0}
        onBlur={e => updateAndSaveLead('email_address', e)}
        value={lead.email_address}
      />
      <TDatePicker
        label="Birth Date"
        timezone="Etc/UTC"
        dateString={lead.birthdate}
        withPortal={false}
        showYearDropdown={true}
        onDateChange={(val) => {
          const value = val?.split('T')?.[0]
          if (moment(value, "YYYY-MM-DD", true).isValid()) {
            const e = new CustomEvent("e", {
              detail: { value },
              bubbles: false,
              cancelable: true,
              composed: false,
            });
            updateAndSaveLead('birthdate', e)
          }
        }}
        dateFormat="MM/dd/yyyy"
        showTimeSelect={false}
      />
      <TItem labelPlacement="stacked" label="Address">
        <TAddressSearch
          value={leadsService.getLeadAddressFormatted(lead)}
          onSelected={async address => {
            const updates = {
              id: lead.id,
              client: lead.client,
              address: address?.streetAddress ?? null,
              address_2: address?.extendedAddress ?? null,
              city: address?.locality ?? null,
              state: address?.region ?? null,
              postal_code: address?.postalCode ?? null,
              country: address?.country ?? null,
              lat: address?.lat ?? '',
              lng: address?.lng ?? '',
              postal_code_4: address?.postalCodeSuffix ?? null
            } as any;

            if (address?.name) {
              updates.company_name = address.name;
            }

            const updated = await leadsService.update(updates);
            leadUpdated(updated, 'address');
          }}
        />
      </TItem>
    </TExpandableItemGroup>
  );
};

export default LeadContactDetails;
