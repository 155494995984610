import React, { useState, useContext, useCallback } from 'react';
import {
  TInfiniteScrollPage,
  TSearchBar,
  TradesRow,
  HapticButton,
} from '../components';
import { TradesFilterModal } from '../components/modals';
import { IonItemDivider } from '@ionic/react';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';
import { tradesService } from '../services';
import { funnel } from 'ionicons/icons';

const TradesPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const { selectedClientId } = state;
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [since, setSince] = useState<string | undefined>(undefined);
  const [until, setUntil] = useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Record<string, string>>({});

  const loadTrades = useCallback(async () => {
    return await tradesService.list({
      clientId: selectedClientId,
      leadAssignedTo: state.user.is_client_admin ? undefined : state.user.id,
      search,
      filters,
    });
  }, [selectedClientId, search, filters]);

  const { next, error, loadNext, loading, data, refresher } = useDataLoader(
    loadTrades,
    true
  );

  const handleSearch = () => {
    setModalOpen(false);
    const filters: Record<string, string> = {};

    if (since) {
      filters.updated_at__gte = since;
    }

    if (until) {
      filters.updated_at__lte = until;
    }

    setFilters(filters);
  };

  return (
    <TInfiniteScrollPage
      onLoadNextPage={loadNext}
      hasNext={!!next}
      onRefresh={refresher}
      toolbarLoadingIndicator={!!search && loading}
      toolbar={
        <IonItemDivider className="no-padding no-margin">
          <TSearchBar
            onSearch={(value: string) => {
              setSearch(value);
            }}
            style={{ width: '100%' }}
            placeholder="Search by lead name or phone number"
          />
            <HapticButton
              icon={funnel}
              size="default"
              onClick={() => {
                setModalOpen(true);
              }}
            />
        </IonItemDivider>
      }
      zeroStateText={
        search ? 'No Trades Found Matching Criteria' : 'No Trades'
      }
      renderItem={(item: any) => (
        <TradesRow key={item.id} item={item} />
      )}
      items={data}
      loading={loading}
      error={error}
    >
      <TradesFilterModal
        isOpen={modalOpen}
        onDidDismiss={() => setModalOpen(false)}
        onApply={handleSearch}
        resetFilters={() => {
          setSince(undefined);
          setUntil(undefined);
          setSearch(undefined);
          setFilters({});
          setModalOpen(false);
          refresher();
        }}
        since={since}
        until={until}
        setSince={setSince}
        setUntil={setUntil}
      />
    </TInfiniteScrollPage>
  )
}

export default TradesPage;