import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { callOutline, mailOutline } from "ionicons/icons";
import {
  archivedLeadsService,
  tradeinStatsService,
  creditAppStatsService,
  quickQualificationsService,
  leadsService,
} from "../services";
import moment from 'moment';
import {
  IonCol,
  IonRow,
  IonCard,
  IonIcon,
  IonText,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonCardContent,
  IonList,
  IonItem,
  IonBadge,
  IonRouterLink,
  IonGrid,
  IonSkeletonText,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  TPage,
  UpcomingAppointmentsCard,
  UnreadNotificationsCard,
  TimeToCallCard,
  Leaderboard,
  UnassignedLeadsCountCard,
  SiteTranslator,
  SmsUsageStats,
} from "../components";
import { AppContext } from "../context/AppContext";
import { LeadContext } from "../context/LeadContext";
import { SetAppointment } from "../types/Appointments";

function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, ""); // remove all non-numeric characters

  // Try to match the 11-digit format first
  let match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/); // 1, followed by groups of 3, 3, and 4 digits
  if (!match) {
    // If no match, try the 10-digit format
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  }

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString; // return original string if no match
}

const Dashboard: React.FC = () => {
  const history = useHistory();
  const loadWaitingLeadsInterval = useRef<any>(null);
  const [needsCall, setNeedsCall] = useState<any>();
  const [usersNeedsCall, setUsersNeedsCall] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [usersWaiting, setUsersWaiting] = useState<any>();
  const [newLeadsToday, setNewLeadsToday] = useState<any>();
  const [newSubmittedLeadsToday, setNewSubmittedLeadsToday] = useState<any>();
  const [newInboundsToday, setNewInboundsToday] = useState<any>();
  const [workingPipeline, setWorkingPipeline] = useState<any>();
  const [pausedPipeline, setPausedPipeline] = useState<any>();
  const [deadGhostPipeline, setDeadGhostPipeline] = useState<any>();
  const [waiting, setWaiting] = useState<any>();
  const [archivedLeadsToday, setArchivedLeadsToday] = useState<any>();
  const [tradeinsToday, setTradeinsToday] = useState<SetAppointment[]>([]);
  const [creditAppsToday, setCreditAppsToday] = useState<SetAppointment[]>([]);
  const [softPullsToday, setSoftPullsToday] = useState<number>(0);
  const { state } = useContext(AppContext);
  const { dispatch } = useContext(LeadContext);

  const loadWaitingLeads = useCallback(async () => {
    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');
    const distinct = true;
    const clientId = state.selectedClientId; // Ensure latest clientId is used
    const leadAssignedTo = state.user.is_client_admin ? undefined : state.user.id;
    if (!clientId) return; // Prevent running if no client is selected

    const [
      needsCall,
      waiting,
      archivedLeadsToday,
      newLeadsToday,
      newSubmittedLeadsToday,
      newInboundsToday,
      workingPipeline,
      pausedPipeline,
      deadGhostPipeline,
      tradeinsToday,
      creditAppsToday,
      softPullsToday,
    ] = await Promise.all([
      leadsService.listNeedsCall({ clientId, pageSize: 500 }),
      leadsService.listWaiting({ clientId, pageSize: 500 }),
      archivedLeadsService.listArchivedLeadsToday(clientId),
      leadsService.listNewToday({ clientId, pageSize: 1 }),
      leadsService.listSubmittedToday({ clientId, pageSize: 1 }),
      leadsService.listHumanInboundsToday({ clientId, pageSize: 1 }),
      leadsService.listWorkingPipeline({ clientId, pageSize: 1 }),
      leadsService.listPausedPipeline({ clientId, pageSize: 1 }),
      leadsService.listDeadGhostPipeline({ clientId, pageSize: 1 }),
      tradeinStatsService.list({
        client_id: clientId,
        lead__assigned_to: leadAssignedTo,
        created_at__gte: todayStart.toISOString(),
        created_at__lte: todayEnd.toISOString(),
        trade_pending_id__isnull: false,
        distinct
      }),
      creditAppStatsService.list({
        client_id: clientId,
        lead__assigned_to: leadAssignedTo,
        completed_at__gte: todayStart.toISOString(),
        completed_at__lte: todayEnd.toISOString(),
        distinct
      }),
      quickQualificationsService.list({
        clientId,
        filters: {
          lead__assigned_to: leadAssignedTo,
          created_at__gte: todayStart.toISOString(),
          created_at__lte: todayEnd.toISOString(),
          distinct
        }
      }),
    ]);

    if (state.selectedClientId !== clientId) return; // Prevent outdated state updates
    const usersWaiting = {} as any;
    const usersNeedsCall = {} as any;

    needsCall.results.forEach((it: any) => {
      const userFullName = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`;
      if (!(userFullName in usersNeedsCall)) {
        usersNeedsCall[userFullName] = needsCall.results.filter(
          (o: any) => o.assigned_to?.id === it.assigned_to?.id
        );
      }
    });

    waiting.results.forEach((it: any) => {
      const userFullName = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`;
      if (!(userFullName in usersWaiting)) {
        usersWaiting[userFullName] = waiting.results.filter(
          (o: any) => o.assigned_to?.id === it.assigned_to?.id
        );
      }
    });
    
    if (softPullsToday?.count > 0) {
      setSoftPullsToday(softPullsToday.count);
    }
    setTradeinsToday(tradeinsToday);
    setCreditAppsToday(creditAppsToday);
    setUsersNeedsCall(usersNeedsCall);
    setUsersWaiting(usersWaiting);
    setNeedsCall(needsCall);
    setWaiting(waiting);
    setArchivedLeadsToday(archivedLeadsToday);
    setNewLeadsToday(newLeadsToday);
    setNewSubmittedLeadsToday(newSubmittedLeadsToday);
    setNewInboundsToday(newInboundsToday);
    setWorkingPipeline(workingPipeline);
    setPausedPipeline(pausedPipeline);
    setDeadGhostPipeline(deadGhostPipeline);
    setLoading(false);
  }, [state.selectedClientId]);

  useEffect(() => {
    if (loadWaitingLeadsInterval.current) {
      clearInterval(loadWaitingLeadsInterval.current);
    }
    setLoading(true);
    loadWaitingLeads();
    loadWaitingLeadsInterval.current = setInterval(loadWaitingLeads, 40000);
    return () => clearInterval(loadWaitingLeadsInterval.current); // Cleanup
  }, [loadWaitingLeads, state.selectedClientId]);

  useIonViewWillEnter(() => {
    if (loadWaitingLeadsInterval.current) {
      clearInterval(loadWaitingLeadsInterval.current);
    }
    setLoading(true);
    loadWaitingLeads();
    loadWaitingLeadsInterval.current = setInterval(loadWaitingLeads, 40000);
  }, [state.selectedClientId]);

  useIonViewWillLeave(() => {
    if (loadWaitingLeadsInterval.current) {
      clearInterval(loadWaitingLeadsInterval.current);
      loadWaitingLeadsInterval.current = null; // Reset ref to prevent re-clearing
    }
  });

  const renderUserList = (o: any, listType: string) => {
    const keys: any = typeof o === "object" ? Object.keys(o) : [];
    if (!keys.length) {
      return (
        <IonItem lines='none'>
          <span role='img' aria-label='cool'>
            😎
          </span>{" "}
          &nbsp;No leads waiting.
        </IonItem>
      );
    }
    return keys.map((key: string) => {
      const keyPcs = key.split("::");
      const userFullName = keyPcs[1] !== "undefined" ? keyPcs[0] : "Unassigned";
      const userId = keyPcs[1] !== "undefined" ? keyPcs[1] : "Unassigned";
      return (
        <IonItem
          className='pointer'
          key={key}
          routerLink='/text-messages/'
          routerDirection='none'
          onClick={() => {
            const changes = {
              assigned_to: {
                id: userId,
                display: userFullName,
              },
              convo_archived: "Active",
            } as any;
            if (listType === "needsCall") {
              changes["needs_call"] = "Call Needed";
            } else if (listType === "waiting") {
              changes["last_message_type"] = "inbound";
            }
            dispatch({
              type: "set",
              value: {
                filters: { ...changes },
              },
            });
          }}
          detail
        >
          {userFullName}
          <IonBadge
            color={listType === "waiting" ? "secondary" : "orange"}
            slot='end'
          >
            {o[key]?.length}
          </IonBadge>
        </IonItem>
      );
    });
  };
  return (
    <TPage loading={false}>
      <IonGrid>
        <IonRow>
          <IonCol sizeSm='12' sizeMd='6'>
            <Leaderboard
              title='Sales Leaderboard'
              userType='assigned_to'
              limit={5}
              showSeeMore={true}
              selectedClientId={state.selectedClientId}
            />
          </IonCol>
          <IonCol sizeSm='12' sizeMd='6'>
            {!!state.selectedClient?.package?.included &&
              !!state.selectedClient?.package?.overage && (
                <SmsUsageStats selectedClientId={state.selectedClientId} />
              )}
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Contact Support</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {/* Email Support */}
                <div className='dashboard-contact-details-container'>
                  <IonIcon icon={mailOutline} size='large' />
                  <IonText className='dashboard-contact-details'>
                    Email us at:
                    <a href='mailto:support@tecobi.com'>support@tecobi.com</a>
                  </IonText>
                </div>
                {/* Text Support */}
                {state?.user?.tecobi_lead_twilio_number && (
                  <div
                    className='dashboard-contact-details-container'
                    style={{ marginTop: "16px" }}
                  >
                    <IonIcon icon={callOutline} size='large' />
                    <IonText className='dashboard-contact-details'>
                      Text us at:
                      <a
                        href={`sms:${state?.user?.tecobi_lead_twilio_number}?body=Help TECOBI!`}
                      >
                        {formatPhoneNumber(
                          state?.user?.tecobi_lead_twilio_number
                        )}
                      </a>
                    </IonText>
                  </div>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='tertiary'>
                <IonCardTitle>Today</IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 365, overflow: "auto" }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          created_at__gte: new Date().toISOString(),
                          created_at__lte: new Date().toISOString(),
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                      detail
                    >
                      New Leads
                      <IonBadge color={"tertiary"} slot='end'>
                        {newLeadsToday?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='forward'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          last_lead_submitted_actual__gte:
                            new Date().toISOString(),
                          last_lead_submitted_actual__lte:
                            new Date().toISOString(),
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                      detail
                    >
                      Leads Submitted
                      <IonBadge color={"tertiary"} slot='end'>
                        {newSubmittedLeadsToday?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          last_human_inbound_time__gte:
                            new Date().toISOString(),
                          last_human_inbound_time__lte:
                            new Date().toISOString(),
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                      detail
                    >
                      Engaged Leads
                      <IonBadge color={"tertiary"} slot='end'>
                        {newInboundsToday?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/reports/archived-leads/'
                      routerDirection='none'
                      onClick={() => {
                        history.push("/reports/archived-leads/");
                      }}
                      detail
                    >
                      Archived Leads
                      <IonBadge color={"tertiary"} slot='end'>
                        {archivedLeadsToday?.length}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className="pointer"
                      routerLink='/credit-applications/'
                      routerDirection='none'
                      detail
                    >
                      Credit Apps
                      <IonBadge color={"tertiary"} slot='end'>
                        {creditAppsToday.length}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className="pointer"
                      routerLink='/quick-qualifications/'
                      routerDirection='none'
                      detail
                    >
                      Soft Pulls
                      <IonBadge color={"tertiary"} slot='end'>
                        {softPullsToday}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className="pointer"
                      routerLink='/trades/'
                      routerDirection='none'
                      detail
                    >
                      Trade-Ins
                      <IonBadge color={"tertiary"} slot='end'>
                        {tradeinsToday.length}
                      </IonBadge>
                    </IonItem>
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='success'>
                <IonCardTitle>Pipelines</IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: "auto" }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          lead_type__in: "SMS,Web,Other,ADF,DMS",
                          classification: "Sales",
                          pause_date: "Unpaused",
                          imported_replied: "2",
                          violators: false,
                          sms_optout: false,
                          carrier_type__in: "null,mobile,voip",
                          status__in: "1,2,23,21,11,12,35",
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                      detail
                    >
                      Auto Bot Working
                      <IonBadge color={"success"} slot='end'>
                        {workingPipeline?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          lead_type__in: "SMS,Web,Other,ADF,DMS",
                          classification: "Sales",
                          pause_date: "Unpaused",
                          imported_replied: "2",
                          violators: false,
                          sms_optout: false,
                          carrier_type__in: "null,mobile,voip",
                          status__in: "28",
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                      detail
                    >
                      Auto Bot Ghost
                      <IonBadge color={"success"} slot='end'>
                        {deadGhostPipeline?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          pause_date: "Paused",
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                      detail
                    >
                      Paused
                      <IonBadge color={"success"} slot='end'>
                        {pausedPipeline?.count}
                      </IonBadge>
                    </IonItem>
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='orange'>
                <IonCardTitle>
                  Customers Needing Call
                  <IonRouterLink
                    routerLink='/text-messages/'
                    routerDirection='root'
                  >
                    <IonBadge
                      className='pointer'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          needs_call: "Call Needed",
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                    >
                      {needsCall?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: "auto" }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>
                    {renderUserList(usersNeedsCall, "needsCall")}
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='secondary'>
                <IonCardTitle>
                  Customers Waiting for SMS
                  <IonRouterLink
                    routerLink='/text-messages/'
                    routerDirection='root'
                  >
                    <IonBadge
                      className='pointer'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          pause_date: "Unpaused",
                          last_message_type: "inbound",
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                    >
                      {waiting?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: "auto" }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>{renderUserList(usersWaiting, "waiting")}</IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <UpcomingAppointmentsCard />
        </IonRow>
      </IonGrid>

      <TimeToCallCard />
      <UnassignedLeadsCountCard />
      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='12' sizeLg='12' sizeXl='12'>
            <UnreadNotificationsCard />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonItem>
        <SiteTranslator
          includedLanguages='es,en'
          id='google_translate_element'
          pageLanguage='en'
        />
      </IonItem>
    </TPage>
  );
};

export default Dashboard;
