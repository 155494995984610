import React, { SyntheticEvent, useRef } from 'react';
import { Grid } from '@giphy/react-components';
import { GiphyFetch, gifPaginator } from '@giphy/js-fetch-api';
import config from '../config.json';
import { IonPopover, IonSearchbar } from '@ionic/react';
import '../styles/components/GiphyPicker.scss';
import { IGif } from '@giphy/js-types';
import { isPlatform } from '@ionic/core';

export interface GiphyPickerProps {
  isOpen: boolean;
  onSelected?: (e: IGif) => void;
  onDidDismiss?: (e: CustomEvent) => void;
}

const GiphyPicker: React.FC<GiphyPickerProps> = ({
  isOpen,
  onSelected,
  onDidDismiss
}) => {
  const grid = useRef<any>();
  const searchRef = useRef<HTMLIonSearchbarElement | null>(null);
  const fetch = new GiphyFetch(config.giphy.apiKey);
  const fetcher = (offset: number) => fetch.trending({ limit: 10, offset });

  const onClick = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onSelected) {
      onSelected(gif);
    }
  };

  const handleSearch = (event: Event) => {
    const target = event.target as HTMLIonSearchbarElement;
    const query = target.value?.trim() || '';

    if (!grid.current) return;

    const searcher = (offset: number) =>
      fetch.search(query, { limit: 10, offset });

    grid.current.paginator = gifPaginator(query ? searcher : fetcher);
    grid.current.setState({
      gifs: [],
      isLoaderVisible: true,
      isDoneFetching: false
    });
    grid.current.onFetch();
  };

  return (
    <IonPopover
      mode={isPlatform('android') ? 'md' : 'ios'}
      isOpen={isOpen}
      // cssClass="giphy-picker"
      className="giphy-picker"
      onDidDismiss={onDidDismiss}
      onDidPresent={() => {
        // Ensures the search bar is focused after the popover is fully rendered
        setTimeout(() => searchRef.current?.setFocus(), 100);
      }}
    >
      <IonSearchbar
        ref={searchRef}
        mode={isPlatform('android') ? 'md' : 'ios'}
        debounce={500} // Use Ionic's built-in debounce
        onIonInput={handleSearch}
        placeholder="Search GIFs"
      />
      {isOpen && (
        <Grid
          ref={grid}
          width={320}
          columns={2}
          gutter={6}
          fetchGifs={fetcher}
          onGifClick={onClick}
        />
      )}
    </IonPopover>
  );
};

export default GiphyPicker;
