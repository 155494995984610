import React, { useState } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
} from "@ionic/react";
import { modalController } from "@ionic/core";
import TModal from "./TModal";
import { ButtonGrid } from ".."; // Assuming ButtonGrid & TModal exist
import { leadVehiclesInterestService } from "../../services";

interface AddVehicleModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: number;
  leadId: number;
  onVehicleAdded: (vehicle: any) => void;
}

const AddVehicleModal: React.FC<AddVehicleModalProps> = ({
  isOpen,
  onClose,
  clientId,
  leadId,
  onVehicleAdded,
}) => {
  const [formData, setFormData] = useState({
    interest: "buy",
    year: "",
    make: "",
    model: "",
    vin: "",
    stock: "",
    trim: "",
    condition: "",
    client: clientId,
    lead: leadId,
    price: "",
    odometer: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const newVehicle = await leadVehiclesInterestService.create(
        clientId,
        leadId,
        formData
      );
      onVehicleAdded(newVehicle);
      modalController.dismiss();
    } catch (e) {
      console.error("Error adding vehicle:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      title='Add Vehicle of Interest'
      className='add-vehicle-modal'
      footer={
        <ButtonGrid>
          <IonButton
            expand='block'
            onClick={handleSubmit}
            disabled={loading}
            color='secondary'
          >
            {loading ? "Adding..." : "Add Vehicle"}
          </IonButton>
          <IonButton expand='block' color='light' onClick={onClose}>
            Cancel
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonContent className='ion-padding'>
        <IonItem>
          <IonLabel position='stacked'>Year</IonLabel>
          <IonInput
            type='tel'
            name='year'
            value={formData.year}
            onIonInput={handleChange}
          />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Make</IonLabel>
          <IonInput
            name='make'
            value={formData.make}
            onIonInput={handleChange}
          />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Model</IonLabel>
          <IonInput
            name='model'
            value={formData.model}
            onIonInput={handleChange}
          />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Condition</IonLabel>
          <IonSelect
            name='condition'
            value={formData.condition}
            onIonChange={handleChange}
          >
            <IonSelectOption value=''>Unknown</IonSelectOption>
            <IonSelectOption value='new'>New</IonSelectOption>
            <IonSelectOption value='used'>Used</IonSelectOption>
            <IonSelectOption value='certified'>Certified</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Odometer</IonLabel>
          <IonInput
            type='tel'
            name='odometer'
            value={formData.odometer}
            onIonInput={handleChange}
          />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>VIN</IonLabel>
          <IonInput name='vin' value={formData.vin} onIonInput={handleChange} />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Stock #</IonLabel>
          <IonInput
            name='stock'
            value={formData.stock}
            onIonInput={handleChange}
          />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Interest</IonLabel>
          <IonSelect
            name='interest'
            value={formData.interest}
            onIonChange={handleChange}
          >
            <IonSelectOption value='buy'>Buy</IonSelectOption>
            <IonSelectOption value='sell'>Sell</IonSelectOption>
          </IonSelect>
        </IonItem>
      </IonContent>
    </TModal>
  );
};

export default AddVehicleModal;
