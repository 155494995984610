import React, {
  createContext,
  useReducer,
  Dispatch,
  useCallback,
  useMemo,
} from 'react';
import { phoneCallsService, leadsService, tradesService } from '../services';
import { TextMessage } from '../services/leads';
import {
  loadingIndicator,
  util,
  appNotification,
  userDefaults,
  actionSheet,
} from '../core';
import {
  checkmarkCircleOutline,
  calendar,
  eye,
  briefcase,
  archive,
  call,
  pause,
  personCircle,
  paperPlane,
  funnel,
  globe,
  ban,
  man,
  key as carKey,
  card,
  carSport,
  notificationsOffCircleOutline,
  phonePortrait,
  megaphone,
} from 'ionicons/icons';
import moment from 'moment';
import { useRouter } from '../hooks';
import { Client } from '../types/Client';
import { ClientHour } from '../types/ClientHour';
import { ClientUser } from '../types/ClientUser';
import { Lead } from '../types/Lead';
import { Message } from '../types/Message';

export interface LeadStateActions {
  type: string;
  value?: any;
}

export interface MediaPreview {
  src: string;
  thumb: string;
  alt?: string;
  contentType: string;
  height?: number;
  width?: number;
}

export interface LeadState {
  next?: string;
  loading: boolean;
  lead?: Lead;
  thread?: Message[];
  error?: any;
  clientUsers: ClientUser[];
  clientHours: ClientHour[];
  leadClient: Partial<Client>;
  internalMessageFilter?: string;
  sendProgress: number;
  sending: boolean;
  showFacebookAd: boolean;
  showPauseModal: boolean;
  showDocumentModal: number;
  showSetAppointmentModal: boolean;
  showSelfSchedulingLinkModal: boolean;
  showInventorySearchModal: boolean;
  showAddTradeModal: boolean;
  surveys?: any[];
  filters?: any;
  mediaPreview?: MediaPreview;
  showLeadFilters: boolean;
  assignDialogDismissed: boolean;
  optinDialogDismissed: boolean;
  showHeaderTooltip: boolean;
  nextLead?: any;
  showLeadNotesModal: boolean;
  autoBotEnabled?: boolean;
}

export interface LeadContextProps {
  state: LeadState;
  dispatch: Dispatch<LeadStateActions>;
  leadUpdated: (lead: any, key?: string) => void;
  createNewMessage: (
    message: Partial<TextMessage>,
    progress?: (pct: number) => void
  ) => Promise<any>;
  createNewInternalMessage: (
    message: Partial<TextMessage>,
    progress?: (pct: number) => void
  ) => Promise<any>;
  callLead: () => void;
  removeInventoryLike: (id: number) => Promise<any>;
  removeTrade: (id: number) => Promise<any>;
  addInventoryLike: (id: number) => Promise<any>;
  updateAndSaveLead: (
    key: string,
    event: CustomEvent,
    user?: any
  ) => Promise<any>;
  resetPauseInLead: (data: any, id: number) => Promise<any>;
  filterDisplay: (key: string, value: any) => string;
  filterIcon: (key: string) => any;
  resetFilters: () => void;
  canViewLead: (user: any, lead?: any) => boolean;
  showAssignLeadDialog: (user: any) => any;
  showOptinLeadDialog: (user: any) => any;
  markHeaderTooltipSeen: () => any;
  getNextLeadLink: (clientId: any, leadId: any) => string;
  nextLeadClientId: () => number | undefined;
  isInbound: boolean;
  isNeedsCall: boolean;
}

export const LeadContext = createContext({} as LeadContextProps);

export async function scrollToBottom(duration?: number) {
  const contentId = 'conversationContent';
  const content = document.getElementById(contentId) as HTMLIonContentElement;
  await content?.scrollToBottom?.(duration);
}

let reducer = (state: LeadState, action: any) => {
  const { type, value } = action;
  switch (type) {
    case 'clear': {
      return {
        ...state,
        loading: true,
        lead: null,
        thread: [],
        surveys: null,
        leadClient: {},
        internalMessageFilter: undefined,
        clientUsers: [],
        clientProviders: [],
        clientHours: [],
        nextLead: null,
        autoBotEnabled: false,
      };
    }

    case 'appendThread': {
      return {
        ...state,
        thread: [...value.reverse(), ...(state.thread ?? [])],
      };
    }

    case 'newMessage': {
      return {
        ...state,
        thread: [...(state.thread ?? []), value],
      };
    }
    case 'updateMessage': {
      const updatedThread = state?.thread?.map(msg =>
        msg.id === action.value.id ? { ...msg, ...action.value } : msg
      );
      return { ...state, thread: updatedThread };
    }
    case 'newMessages': {
      let { thread = [] } = state;

      if (value && value.length) {
        const threadIds = thread.map((it: any) => it.id);
        thread = thread.concat(
          value.filter((it: any) => threadIds.indexOf(it.id) === -1)
        );
      }

      return {
        ...state,
        thread,
      };
    }

    case 'internalMessageFilter': {
      return {
        ...state,
        internalMessageFilter: value,
      };
    }

    case 'set': {
      const s = { ...state };

      if (value?.filters) {
        window.localStorage.setItem('filters', JSON.stringify(value.filters));
      }

      return Object.assign(s, value);
    }

    case 'toggle': {
      const s = { ...state } as any;
      for (var i = 0; i < value.length; i++) {
        const key = value[i];
        s[key] = !s[key];
      }

      return s;
    }
  }
  return state;
};

let assignDialog: any;
let optinDialog: any;
export const LeadContextProvider = (props: any) => {
  const filterDefaults = useMemo(() => {
    return {
      convo_archived: 'Active',
    }
  }, [])

  let savedFilters: any = filterDefaults;
  try {
    savedFilters = JSON.parse(window.localStorage.getItem('filters') ?? '');
  } catch (e) {}

  const initialState: LeadState = {
    loading: true,
    sending: false,
    sendProgress: 0,
    showFacebookAd: false,
    showPauseModal: false,
    showDocumentModal: 0,
    showSetAppointmentModal: false,
    showSelfSchedulingLinkModal: false,
    showInventorySearchModal: false,
    showAddTradeModal: false,
    showHeaderTooltip: false,
    filters: savedFilters,
    clientUsers: [],
    clientHours: [],
    leadClient: {},
    internalMessageFilter: undefined,
    showLeadFilters: false,
    assignDialogDismissed: false,
    optinDialogDismissed: false,
    showLeadNotesModal: false,
    autoBotEnabled: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();
  const isInbound = useMemo(
    () => util.locationIsInbound(router.location),
    [router]
  );

  const isNeedsCall = useMemo(
    () => util.locationIsNeedsCall(router.location),
    [router]
  );

  const createNewMessage = useCallback(
    async (
      message: Partial<TextMessage>,
      progressHandler?: (pct: number) => void
    ) => {
      const lead = state.lead;
      const clientId = lead?.client;
      const leadId = lead?.id;
      const { twilio_number, phone_number } = lead;
      const res = await leadsService.sendMessage(
        clientId,
        leadId,
        Object.assign(
          {
            twilio_number,
            number: phone_number,
          },
          message
        ),
        progressHandler
      );

      dispatch({
        type: 'newMessage',
        value: res.data,
      });

      util.defer(() => scrollToBottom(150));

      return res;
    },
    [dispatch, state.lead]
  );

  const createNewInternalMessage = useCallback(
    async (
      message: Partial<TextMessage>,
      progressHandler?: (pct: number) => void
    ) => {
      return await createNewMessage(
        Object.assign(message, { text_ninja: true }),
        progressHandler
      );
    },
    [createNewMessage]
  );

  const doCallLead = useCallback(
    async (numberType?: string) => {
      const lead = state.lead;
      const { id, phone_number, best_name, work_phone } = lead ?? {};
      if (phone_number || work_phone) {
        try {
          await loadingIndicator.create();
          await phoneCallsService.call(id, best_name, numberType);
          // Visually turn off the needs call orange box and toggler slider
          if (lead.needs_call) {
            lead.needs_call = null;
            lead.needs_call_since = null;
            dispatch({
              type: 'set',
              value: { lead },
            });
          }
        } finally {
          loadingIndicator.dismiss();
        }
      }
    },
    [state.lead]
  );

  const callLead = useCallback(() => {
    const { lead } = state;
    const btns: any = [
      {
        text: `Cell ${util.formatHumanReadablePhone(lead.phone_number)}`,
        handler: () => doCallLead('phone_number'),
      },
    ];
    if (lead?.work_phone) {
      btns.push({
        text: `Work ${util.formatHumanReadablePhone(lead.work_phone)}`,
        handler: () => doCallLead('work_phone'),
      });
    }
    if (lead?.home_phone) {
      btns.push({
        text: `Home ${util.formatHumanReadablePhone(lead.home_phone)}`,
        handler: () => doCallLead('home_phone'),
      });
    }
    btns.push({
      text: 'Cancel',
      role: 'cancel',
    });
    if (btns.length > 2) {
      actionSheet.show(btns);
    } else {
      doCallLead('phone_number');
    }
  }, [state, doCallLead]);

  const leadUpdated = useCallback(
    async (lead: any, key?: string, user?: any) => {
      if (!lead || lead.id !== state.lead?.id) {
        return;
      }
      dispatch({ type: 'set', value: { lead } });

      switch (key) {
        case 'no_ninja':
          createNewInternalMessage({
            message: `No ninja contact set to ${lead.no_ninja}`,
          });
          break;
        case 'no_automation':
          createNewInternalMessage({
            message: `No automation set to ${lead.no_automation}`,
          });
          break;
        case 'no_blast':
          createNewInternalMessage({
            message: `No blast set to ${lead.no_blast}`,
          });
          break;
        case 'assigned_to':
          let message = lead.assigned_to
            ? `Lead assigned to ${lead.assigned_to_info.full_name}.`
            : 'Lead unassigned.';
          if (user && lead.assigned_to && lead.assigned_to !== user.id) {
            message += `\n@${lead.assigned_to_info.email.split('@')[0]}`;
          }
          createNewInternalMessage({
            message,
          });
          break;
      }

      util.delay(scrollToBottom, 100);
    },
    [dispatch, state.lead, createNewInternalMessage]
  );

  const removeTrade = useCallback(
    async (id: number) => {
      const lead = state.lead;
      const clientId = lead?.client;
      const leadId = lead?.id;
      const trades = lead?.tradeins ?? [];
      try {
        await loadingIndicator.create();
        const trade = trades.find((it: any) => it.id === id);
        await tradesService.removeTrade(clientId, leadId, id);
        await createNewInternalMessage({
          message: `Removed trade: ${util.ymmt(trade ?? {})}.`,
        });
        lead.tradeins = trades.filter((it: any) => it.id !== id);
        dispatch({
          type: 'set',
          value: { lead },
        });
      } finally {
        loadingIndicator.dismiss();
      }
    },
    [dispatch, state.lead, createNewInternalMessage]
  );

  const addInventoryLike = useCallback(
    async (item: any) => {
      const { id } = item;
      const lead = state.lead;
      const clientId = lead?.client;
      const leadId = lead?.id;
      const likes = lead?.inventory_likes ?? [];

      try {
        await loadingIndicator.create();
        const { data } = await leadsService.addInventoryLike(
          clientId,
          leadId,
          id
        );
        await createNewInternalMessage({
          message: `Added liked vehicle: ${util.ymmt(item)}.`,
        });
        item.image = item.default_image;
        const like = { id: data.id, inventory: item };
        lead.inventory_likes = [...likes, like];
        dispatch({ type: 'set', value: { lead } });
      } finally {
        loadingIndicator.dismiss();
      }
    },
    [dispatch, createNewInternalMessage, state.lead]
  );

  const removeInventoryLike = useCallback(
    async (id: number) => {
      const lead = state.lead;
      const clientId = lead?.client;
      const leadId = lead?.id;
      const likes = lead?.inventory_likes ?? [];

      try {
        await loadingIndicator.create();
        const item = likes.find((it: any) => it.id === id);
        await leadsService.removeInventoryLike(clientId, leadId, id);
        await createNewInternalMessage({
          message: `Removed liked vehicle: ${util.ymmt(item?.inventory)}.`,
        });
        lead.inventory_likes = likes.filter((it: any) => it.id !== id);
        dispatch({
          type: 'set',
          value: { lead },
        });
      } finally {
        loadingIndicator.dismiss();
      }
    },
    [dispatch, createNewInternalMessage, state.lead]
  );

  const updateAndSaveLead = useCallback(
    async (key: string, event: CustomEvent, user?: any) => {
      const lead = state.lead;
      const v = event.detail.value;

      if (lead[key] === v) {
        return;
      }

      lead[key] = v;
      const data = await leadsService.update({
        client: lead.client,
        id: lead.id,
        [key]: v,
      });
      leadUpdated(data, key, user);
    },
    [leadUpdated, state.lead]
  );

  const resetPauseInLead = useCallback(
    async (data: any, id: number) => {
      const { client } = state.lead;
      await loadingIndicator.create();

      const lead = await leadsService.update({
        client,
        id: state.lead.id,
        pause_date: data.date.toISOString(),
        pause_message: data.message,
      });

      const thread = state.thread;

      thread.forEach((it: any) => {
        if (it.id === id) {
          it.resetPause = null;
        }
      });

      dispatch({
        type: 'set',
        value: {
          thread,
        },
      });

      leadUpdated(lead);

      await createNewInternalMessage({
        message: `Pause Date ${data.date.format('MM/DD/YY hh:mma zz')}`,
      });

      if (data.message) {
        await createNewInternalMessage({
          message: `Pause Message: ${data.message}`,
        });
      }

      loadingIndicator.dismiss();
    },
    [createNewInternalMessage, dispatch, leadUpdated, state.lead, state.thread]
  );

  const filterDisplay = useCallback((key: string, value: any) => {
    if (typeof value === 'object') {
      return value.display;
    }
    if (key === 'status__in' && value === '1,2,23,21,11,12,35') {
      return 'Default Blast Statuses';
    }
    if (key === 'sms_optout' && String(value) === 'false') {
      return 'Exclude Opt-out';
    }
    if (key === 'sms_optout' && String(value) === 'true') {
      return 'Include Opt-out';
    }
    if (key === 'carrier_type__in' && value === 'null,mobile,voip') {
      return 'Exclude Landlines';
    }
    if (key === 'violators' && String(value) === 'false') {
      return 'Exclude Violators';
    }
    if (key === 'violators' && String(value) === 'true') {
      return 'Include Violators';
    }
    if (key === 'no_blast' && String(value) === 'true') {
      return 'Include no blasts'
    }
    if (key === 'no_blast' && String(value) === 'false') {
      return 'Exclude no blasts'
    }
    if (key === 'imported_replied' && value === '2') {
      return 'Exclude Imported/No-reply';
    }
    if (key === 'imported_replied__in' && value === 'null') {
      return 'Not Imported';
    }
    if (key === 'imported_replied__in' && value === 'true,false') {
      return 'Imported';
    }
    if (key === 'imported_replied__in' && value === 'true') {
      return 'Imported + Inbound';
    }
    if (key === 'imported_replied__in' && value === 'false') {
      return 'Imported + No-Inbound';
    }
    switch (key) {
      case 'created_at__gte':
      case 'created_at__lte':
      case 'last_human_inbound_time__gte':
      case 'last_human_inbound_time__lte':
      case 'last_message__gte':
      case 'last_message__lte':
      case 'last_lead_submitted_actual__gte':
      case 'last_lead_submitted_actual__lte':
        const dateString = moment(value).format('MM/DD/YY');
        return `${
          key.match(/last_lead_submitted_actual/i)
            ? 'Lead Submitted'
            : key.match(/last_human_inbound_time/i)
            ? 'Last Human Inbound'
            : key.match(/last_message/i)
            ? 'Last Message'
            : 'Created'
        } ${key.match(/gte/i) ? 'Since' : 'Until'}: ${dateString}`;

      default:
        return value;
    }
  }, []);

  const filterIcon = useCallback((key: string) => {
    switch (key) {
      case 'status':
        return checkmarkCircleOutline;
      case 'language':
        return globe;
      case 'violators':
        return ban;
      case 'sms_optout':
        return notificationsOffCircleOutline;
      case 'no_blast':
        return megaphone;
      case 'human':
        return man;
      case 'created_at__gte':
      case 'created_at__lte':
      case 'last_message__gte':
      case 'last_message__lte':
        return calendar;
      case 'carrier_type':
      case 'carrier_type__in':
        return phonePortrait;
      case 'watchers':
        return eye;
      case 'classification':
        return briefcase;
      case 'convo_archived':
        return archive;
      case 'twilio_number':
        return call;
      case 'pause_date':
        return pause;
      case 'assigned_to':
        return personCircle;
      case 'master_provider':
        return paperPlane;
      case 'trade_stage':
        return carKey;
      case 'credit_stage':
        return card;
      case 'vehicle_stage':
        return carSport;
      case 'appt_stage':
        return calendar;
      default:
        return funnel;
    }
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({
      type: 'set',
      value: {
        filters: filterDefaults,
        showLeadFilters: false,
      },
    });
  }, [dispatch, filterDefaults]);

  const showAssignLeadDialog = useCallback(
    (user: any) => {
      const lead = state.lead;
      const assignDialogDismissed = state.assignDialogDismissed;

      if (
        lead &&
        lead.status &&
        !assignDialog &&
        !assignDialogDismissed &&
        !lead.assigned_to &&
        !user.is_text_ninja &&
        !user.is_client_admin
      ) {
        const { id, client } = lead;
        assignDialog = appNotification.alert({
          header: 'Assign Lead',
          message: 'Would you like to assign this lead to yourself?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                dispatch({
                  type: 'set',
                  value: {
                    assignDialogDismissed: true,
                  },
                });
                router.replace('/text-messages/', {});
                assignDialog = null;
              },
            },
            {
              text: 'Yes',
              handler: async () => {
                const updated = await leadsService.update(
                  {
                    client,
                    id,
                    assigned_to: user.id,
                    assigned_by: user.id,
                  },
                  { noToast: true }
                );

                dispatch({
                  type: 'set',
                  value: {
                    lead: updated,
                    assignDialogDismissed: false,
                  },
                });

                appNotification.toast(
                  'Lead has been assigned to you.',
                  'Lead Assigned'
                );

                assignDialog = null;
              },
            },
          ],
        });
      }
    },
    [router, dispatch, state.lead, state.assignDialogDismissed]
  );

  const showOptinLeadDialog = useCallback(
    (user: any) => {
      const lead = state.lead;
      const optinDialogDismissed = state.optinDialogDismissed;

      if (
        lead &&
        lead.sms_opt_out &&
        lead.sms_opt_out > 8 &&
        !optinDialog &&
        !optinDialogDismissed &&
        !user.is_text_ninja &&
        !!(user.is_client_admin || user.is_staff)
      ) {
        const { id, client, convo_archived, sms_opt_out, twilio_number_str } =
          lead;
        const buttons: any = [];
        const isSoft: boolean = sms_opt_out > 8 && sms_opt_out < 10;
        if (!!convo_archived) {
          buttons.push({
            text: 'Close',
            role: 'cancel',
            handler: () => {
              dispatch({
                type: 'set',
                value: {
                  optinDialogDismissed: true,
                },
              });
              optinDialog = null;
            },
          });
        }
        if (!convo_archived) {
          buttons.push({
            text: 'Archive',
            handler: async () => {
              const updated = await leadsService.archive(client, id, {
                noToast: true,
              });

              dispatch({
                type: 'set',
                value: {
                  lead: updated,
                  optinDialogDismissed: true,
                },
              });

              appNotification.toast('Lead has been archived.', 'Lead Updated');
              optinDialog = null;
            },
          });
        }
        if (isSoft) {
          buttons.push({
            text: 'Opt Back in',
            handler: async () => {
              const updated = await leadsService.update(
                {
                  client,
                  id,
                  sms_opt_out: 0,
                  status: 1,
                  convo_archived: false,
                },
                { noToast: true }
              );
              dispatch({
                type: 'set',
                value: {
                  lead: updated,
                  optinDialogDismissed: true,
                },
              });

              appNotification.toast('Lead has been opted in.', 'Lead Opt-in');
              optinDialog = null;
            },
          });
        }
        optinDialog = appNotification.alert({
          header: 'Lead has Opted Out',
          message: isSoft
            ? 'We have detected that this customer may not want to be contacted anymore. You can archive the lead if this is accurate.  You can override this notice to enable texting again. Please be aware that if you contact the customer after they have requested to not be contacted it is a violation of the TCPA.'
            : `This customer has requested to not be text messaged any longer. The only way to reactivate texting is to have the customer text in the word START to ${util.formatHumanReadablePhone(
                twilio_number_str
              )}`,
          buttons: buttons,
        });
      }
    },
    [
      dispatch,
      state.lead,
      state.optinDialogDismissed,
    ]
  );

  const canViewLead = useCallback(
    (user: any, lead?: any) => {
      lead = lead || state.lead;
      return (
        !lead ||
        user.is_text_ninja ||
        user.is_client_admin ||
        !lead.status ||
        !lead.assigned_to ||
        lead.assigned_to === user.id ||
        lead.bdc_rep === user.id ||
        lead.sales_manager === user.id ||
        lead.secondary_rep === user.id ||
        lead.finance_manager === user.id ||
        lead.assigned_to?.id === user.id ||
        lead.service_manager?.id === user.id ||
        lead.parts_manager?.id === user.id
      );
    },
    [state.lead]
  );

  const markHeaderTooltipSeen = useCallback(() => {
    if (state.showHeaderTooltip) {
      userDefaults.setValue('seen-chat-header-tooltip', 'true');
      dispatch({
        type: 'set',
        value: { showHeaderTooltip: false },
      });
    }
  }, [dispatch, state.showHeaderTooltip]);

  const getNextLeadLink = useCallback(
    (clientId: any, leadId: any) => {
      return leadsService.getLink(clientId, leadId, isInbound, isNeedsCall);
    },
    [isInbound, isNeedsCall]
  );

  const nextLeadClientId = useCallback(() => {
    return !(isInbound || isNeedsCall) ? state.lead?.client : undefined;
  }, [isInbound, isNeedsCall, state.lead]);

  const value = useMemo(
    () =>
      ({
        state,
        dispatch,
        createNewMessage,
        createNewInternalMessage,
        callLead,
        leadUpdated,
        removeTrade,
        addInventoryLike,
        removeInventoryLike,
        updateAndSaveLead,
        resetPauseInLead,
        filterDisplay,
        filterIcon,
        resetFilters,
        showAssignLeadDialog,
        showOptinLeadDialog,
        canViewLead,
        markHeaderTooltipSeen,
        getNextLeadLink,
        nextLeadClientId,
        isInbound,
        isNeedsCall,
      } as LeadContextProps),
    [
      state,
      dispatch,
      createNewMessage,
      createNewInternalMessage,
      callLead,
      leadUpdated,
      removeTrade,
      addInventoryLike,
      removeInventoryLike,
      updateAndSaveLead,
      resetPauseInLead,
      filterDisplay,
      filterIcon,
      resetFilters,
      showAssignLeadDialog,
      showOptinLeadDialog,
      canViewLead,
      markHeaderTooltipSeen,
      getNextLeadLink,
      nextLeadClientId,
      isInbound,
      isNeedsCall,
    ]
  );

  return (
    <LeadContext.Provider value={value}>{props.children}</LeadContext.Provider>
  );
};

export const LeadContextConsumer = LeadContext.Consumer;
